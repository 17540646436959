import { computed, inject, provide, reactive } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import {
  getGeocodeAPI,
  getGeometryLocation,
  getAddressArray,
  getFormatAddress,
  getGeoJsonLocation,
} from "../utils";

export const initStore = () => {
  // global store VUEX
  const store = useStore();

  const DEFAULT_ZOOM = 2.3;
  const DEFAULT_CENTER = { lat: 37.28901830103057, lng: -5.432797554247943 }; // spain for precision center map,

  // state
  const state = reactive({
    isLoading: true,
    loadingPaginateStudy: null,
    defaultKeywords: store.getters.acfOptions()?.search_trial?.keywords || "",
    googleMapKey: store.getters.acfOptions()?.google_map_key || "",
    ipGeoLocationKey: store.getters.acfOptions()?.ip_geo_location || "",
    locationUser: {
      city: null,
      country: null,
      lat: null,
      lng: null,
    },
    centerMap: DEFAULT_CENTER,
    zoomMap: DEFAULT_ZOOM,
    locationSearch: {
      zip: null,
      country: null,
      state: null,
      city: null,
      searchText: null,
      lat: null,
      lng: null,
    },
    markerSearch: null,
    listMarkers: [],
    studyResult: [],
    cursor: 1,
    perPage: 50,
    page: 1,
    totalResults: 0,
  });

  // Getters
  const getSearchText = computed(() => state.searchText);
  const getGoogleMapId = computed(() => state.googleMapId);
  const getGoogleMapKey = computed(() => state.googleMapKey);
  const getIpGeoLocationKey = computed(() => state.ipGeoLocationKey);
  const getListMarkers = computed(() => state.listMarkers);
  const getLocationSearch = computed(() => state.locationSearch);
  const getIsLoading = computed(() => state.isLoading);
  const getDefaultKeywords = computed(() => state.defaultKeywords);
  const getStudyResult = computed(() => state.studyResult);
  const getCenterMap = computed(() => state.centerMap);
  const getZoomMap = computed(() => state.zoomMap);
  const getMarkerSearch = computed(() => state.markerSearch);
  const getLoadingPaginateStudy = computed(() => state.loadingPaginateStudy);

  // Actions

  const centerMapWithMarkerClick = (id) => {
    const markerFilter = [...state.listMarkers][id];
    if (markerFilter) {
      state.isLoading = true;
      state.centerMap = markerFilter?.location?.lat
        ? markerFilter.location
        : DEFAULT_CENTER;
      state.zoomMap = markerFilter?.location?.lat ? 12 : DEFAULT_ZOOM;
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    }
  };

  const setlocationSearch = async (newSearch) => {
    state.markerSearch = null;
    state.isLoading = true;
    state.locationSearch = newSearch;
    let filterBy = "locationSearch";
    if (!newSearch.city && !newSearch.country && newSearch.searchText) {
      const resGoogle = await getGeocodeAPI(
        newSearch.searchText,
        state.googleMapKey
      );
      const resultGeometry = getGeometryLocation(resGoogle);
      if (resultGeometry) {
        state.markerSearch = resultGeometry;
        state.centerMap = resultGeometry;
        state.zoomMap = 6;

        const address_components = getAddressArray(resGoogle);
        let newLocationSearch = {
          ...state.locationSearch,
          ...getFormatAddress(address_components),
          lat: resultGeometry.lat,
          lng: resultGeometry.lng,
        };
        state.locationSearch = newLocationSearch;
      } else {
        state.centerMap = DEFAULT_CENTER;
        state.zoomMap = DEFAULT_ZOOM;
      }
    } else if (newSearch.lat && newSearch.lng) {
      state.markerSearch = {
        lat: newSearch.lat,
        lng: newSearch.lng,
      };
      state.centerMap = {
        lat: newSearch.lat,
        lng: newSearch.lng,
      };
      state.zoomMap = 5;
    }

    if (!state.locationSearch?.country && !state.locationSearch?.lat) {
      state.centerMap = DEFAULT_CENTER;
      state.zoomMap = DEFAULT_ZOOM;
      filterBy = "locationUser";
    }

    if (filterBy === "locationSearch") {
      state.studyResult = [...state.studyResult].sort((a) => {
        if (
          a.status === "Recruiting" &&
          toLower(a.country) === toLower(state.locationSearch.country)
        )
          return -1;
        return 0;
      });
    } else {
      state.studyResult = [...state.studyResult].sort((a) => {
        if (
          a.status === "Recruiting" &&
          toLower(a.country) === toLower(state.locationUser.country)
        )
          return -1;
        return 0;
      });
    }
    setTimeout(() => {
      state.isLoading = false;
    }, 700);
  };

  const toLower = (text) => {
    if (!text) return null;
    return text.toLowerCase();
  };

  const formatStudyWithPlaces = (data) => {
    const newStudyResult = [];
    let count = 0;
    if (data.NStudiesFound) {
      const fullStudies = data.FullStudies;
      fullStudies.forEach((valParent) => {
        const locationList =
          valParent.Study?.ProtocolSection?.ContactsLocationsModule
            ?.LocationList?.Location || [];

        locationList.forEach((valChild) => {
          const getStatus = (valChild?.LocationStatus || "").toLowerCase();
          if (
            getStatus === "recruiting" ||
            getStatus === "active, not recruiting"
          ) {
            newStudyResult.push({
              index: count,
              country: valChild.LocationCountry,
              city: valChild.LocationCity || null,
              direction: valChild.LocationFacility || null,
              state: valChild.LocationState || null,
              investigator:
                valChild.LocationContactList?.LocationContact[0]
                  ?.LocationContactName || null,
              zip: valChild.LocationZip || null,
              status: valChild.LocationStatus || null,
            });
            count = count + 1;
          }
        });
      });
    }
    return newStudyResult;
  };

  const fetchAPITrials = async () => {
    try {
      const dataGeoJson = await getGeoJsonLocation(state.ipGeoLocationKey);
      if (dataGeoJson) {
        state.locationUser = dataGeoJson;
      }
      const BASE_URI = "https://clinicaltrials.gov/api/query/full_studies";

      let baseExpresion = `${getDefaultKeywords.value} AND FibroGen AND Recruiting AND Zephyrus II`;

      const params = {
        expr: baseExpresion,
        min_rnk: state.cursor,
        max_rnk: state.perPage,
        fmt: "json",
      };
      const res = await axios.get(BASE_URI, {
        params: params,
      });
      // StudyFieldsResponse
      const { FullStudiesResponse } = res.data;
      const dataFormatted = formatStudyWithPlaces(FullStudiesResponse);
      const sortData = [...dataFormatted].sort((a) => {
        if (a.status !== "Recruiting") return -1;
        return 0;
      });

      const randomPageSize = Math.floor(Math.random() * (45 - 25) + 45);
      let pageSizeAddress = randomPageSize;
      const paginateResults = Math.round(
        dataFormatted.length / pageSizeAddress
      );
      let paginateSearchAddress = 1;

      let newMarkerList = [];
      do {
        state.loadingPaginateStudy = `Loading ${newMarkerList.length} of ${dataFormatted.length} studies`;
        let sliceData = [...sortData].slice(
          (paginateSearchAddress - 1) * pageSizeAddress,
          paginateSearchAddress * pageSizeAddress
        );
        let allAddress = await axios.all(
          sliceData.map((val) => {
            let fullDirection = val?.direction || "";
            if (val.state) {
              fullDirection = fullDirection.concat(` ${val.state}`);
            }
            if (val.city) {
              fullDirection = fullDirection.concat(` ${val.city}`);
            }
            if (val.country) {
              fullDirection = fullDirection.concat(` ${val.country}`);
            }
            return getGeocodeAPI(fullDirection, state.googleMapKey);
          })
        );

        let sliceMarkerList = [];
        allAddress.forEach((valAddress, indexAddress) => {
          const result = getGeometryLocation(valAddress);
          if (result) {
            sliceMarkerList.push({
              location: result,
              info: sliceData[indexAddress],
            });
            state.loadingPaginateStudy = `Loading ${
              newMarkerList.length + sliceMarkerList.length
            } of ${dataFormatted.length} studies`;
          }
        });
        newMarkerList = [...newMarkerList, ...sliceMarkerList];
        paginateSearchAddress = paginateSearchAddress + 1;
        await new Promise((resolve) => setTimeout(resolve, 700));
        state.isLoading = false;
        state.studyResult = [...state.studyResult, ...sliceData];
        state.listMarkers = newMarkerList;
      } while (paginateSearchAddress <= paginateResults);

      state.studyResult = [...dataFormatted].sort((a) => {
        if (
          a.status === "Recruiting" &&
          toLower(a.country) === toLower(state.locationUser.country)
        )
          return -1;
        return 0;
      });

      state.loadingPaginateStudy = `Loading ${newMarkerList.length} of ${dataFormatted.length} locations`;
      state.listMarkers = newMarkerList;

      setTimeout(() => {
        state.loadingPaginateStudy = null;
      }, 1000);
    } catch (e) {
      console.log("e", e);
      state.studyResult = [];
      state.listMarkers = [];
      state.centerMap = DEFAULT_CENTER;
      state.zoomMap = DEFAULT_ZOOM;
    }
    state.isLoading = false;
  };

  provide("getGoogleMapKey", getGoogleMapKey);
  provide("getGoogleMapId", getGoogleMapId);
  provide("getIpGeoLocationKey", getIpGeoLocationKey);
  provide("getSearchText", getSearchText);
  provide("getListMarkers", getListMarkers);
  provide("getLocationSearch", getLocationSearch);
  provide("getIsLoading", getIsLoading);
  provide("getStudyResult", getStudyResult);
  provide("getCenterMap", getCenterMap);
  provide("getZoomMap", getZoomMap);
  provide("getMarkerSearch", getMarkerSearch);
  provide("getLoadingPaginateStudy", getLoadingPaginateStudy);

  provide("fetchAPITrials", fetchAPITrials);
  provide("setlocationSearch", setlocationSearch);
  provide("centerMapWithMarkerClick", centerMapWithMarkerClick);
};

export const useStoreProvideInject = () => ({
  // getters
  googleMapId: inject("getGoogleMapId"),
  googleMapKey: inject("getGoogleMapKey"),
  ipGeoLocationKey: inject("getIpGeoLocationKey"),
  searchText: inject("getSearchText"),
  listMarkers: inject("getListMarkers"),
  locationSearch: inject("getLocationSearch"),
  isLoading: inject("getIsLoading"),
  studyResult: inject("getStudyResult"),
  centerMap: inject("getCenterMap"),
  zoomMap: inject("getZoomMap"),
  markerSearch: inject("getMarkerSearch"),
  loadingPaginateStudy: inject("getLoadingPaginateStudy"),

  // actions / setters
  setlocationSearch: inject("setlocationSearch"),
  fetchAPITrials: inject("fetchAPITrials"),
  centerMapWithMarkerClick: inject("centerMapWithMarkerClick"),
});
