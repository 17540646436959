<template>
  <!-- <div v-if="dataCollapse.about_us" class="ContainerCollapse">
    <AboutUs_FAQ v-if="dataCollapse.about_us" :data="dataCollapse.about_us" />
  </div> -->
  <div class="ContainerCollapse" data-aos="fade-right" data-aos-duration="500">
    <h3 class="titleSection">
      {{ dataCollapse.title }}
    </h3>
    <div class="contentCollapse">
      <CollapseComponent
        v-for="(valueItem, index) in dataCollapse.item"
        :key="index"
        :data="valueItem"
      />
    </div>
  </div>
</template>
<script>
import CollapseComponent from "./CollapseComponent.vue";
// import AboutUs_FAQ from "./AboutUs_FAQ.vue";
export default {
  name: "CollapseFAQ",
  props: ["data"],
  components: {
    CollapseComponent,
    // AboutUs_FAQ,
  },
  setup(props) {
    const dataCollapse = props.data;
    // console.log("dataCollapse", dataCollapse);
    return {
      dataCollapse,
    };
  },
};
</script>
<style scoped lang="scss">
.titleSection {
  font-weight: 400;
  @apply font-Fraunces text-[45px] !leading-[60px]  md:text-[55px] md:!leading-[70px] lg:text-[64px] lg:!leading-[100px] border-b border-b-black;
}
.ContainerCollapse {
  @apply max-w-screen-xl w-full px-7 2xl:px-0 mx-auto py-7 flex flex-col gap-7;
}
.contentCollapse {
  @apply py-6 px-0 md:p-6;
}
</style>
