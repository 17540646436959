<template>
  <div class="containerModal">
    <FormRenderVue v-bind="form" />
  </div>
</template>

<script>
import FormRenderVue from "../../JoinForm/FormRender.vue";
export default {
  name: "ModalJoinForm",
  components: {
    FormRenderVue,
  },
  props: ["data", "closeModal"],
  setup(props) {
    const closeModal = () => {
      if (props.closeModal) {
        props.closeModal();
      }
    };
    return {
      form: {
        ...props.data,
        onSubmitAction: closeModal,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.containerModal {
  @apply w-[90%] max-w-[300px] md:w-[400px] lg:w-[550px] lg:max-w-[600px] bg-white p-7 lg:p-16 rounded-[10px];
}
</style>