<template>
    <kinesis-container>
      <div class="signuphero">
        <div class="imgtitle">
          <kinesis-element class="parallax" strength="300" axis="x">
            <h2 class="title">{{ data.title }}</h2>
          </kinesis-element>
          <div class="contentandimage">
            <kinesis-element :strength="0">
              <img class="img" :src="data.image.url" />
            </kinesis-element>
            <kinesis-element class="parallax" strength="300" axis="x">
              <h2 class="title text-white">{{ data.title }}</h2>
            </kinesis-element>
          </div>
        </div>

        <div class="sectioncontent">
          <p class="content">{{ data.content }}</p>
        </div>
      </div>
    </kinesis-container>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
export default {
  props: ["data"],
  components: {
    KinesisContainer,
    KinesisElement,
  },
  mounted() {
    //console.log(this.data);
  },
};
</script>

<style scoped lang="scss">
.signuphero:deep() {
  @apply max-w-screen-xl mx-auto py-10 relative;

  .parallax {
    @apply absolute top-0 left-0 right-0 bottom-0 m-auto;
  }

  .imgtitle {
    @apply mx-8 relative;

    .contentandimage {
      @apply relative relative m-auto md:w-max overflow-hidden;

      .title {
        @apply text-white md:ml-[-37px] lg:ml-[-158px] xl:ml-[-283px] w-max;
      }
    }

    .img {
      @apply m-auto mr-3 mb-3 !max-w-[570px] min-h-[450px] md:min-h-[750px] object-cover;
      box-shadow: 10px 10px 0px 0px rgba(209, 237, 176, 1);
    }

    .title {
      @apply text-center text-5xl md:text-9xl lg:text-11xl xl:text-14xl font-bold text-black absolute top-0 left-0 right-0 bottom-0 m-auto h-min font-Fraunces font-400;
    }
  }

  .sectioncontent {
    @apply relative mx-10 lg:mx-0;
    .content {
      @apply my-20 text-center text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-black font-Fraunces font-400 leading-9;
    }
  }
}
</style>