<template>
  <!-- <h1>{{data.title}}</h1>
  <h1 v-html="data.title"/> -->
  <section class="parallaxhero">
    <div
      class="hero-background-parallax"
      :style="`background: url('${data.background_image.url}')`"
    >
      <img :src="`${data.mask_image.url}`" alt="" />
      <img
        class="playButton heartbeat"
        :src="playSvg"
        @mouseenter="hoverEnterPlayButton"
        @mouseleave="hoverEndPlayButton"
        @click="toggleModal"
      />
    </div>

    <div class="title">
      <div class="text-title" v-html="data.title" />
    </div>

    <div class="content">
      <p v-html="data.content" />
    </div>
  </section>
  <UniversalModal v-model="isOpenModal" :close="toggleModal">
    <div class="modalContainerVideo" @click="toggleModal">
      <ModalVideo @toggleModal="toggleModal" />
    </div>
  </UniversalModal>
</template>

<script>
import ModalVideo from "./ModalVideo/ModalVideo.vue";

import svgPlayWhite from "@/assets/img/playWhite.svg";

export default {
  props: ["data"],
  data() {
    return {
      playSvg: svgPlayWhite,
      isOpenModal: false,
    };
  },
  components: {
    ModalVideo,
  },
  methods: {
    hoverEnterPlayButton() {
      this.playSvg = svgPlayWhite;
    },
    hoverEndPlayButton() {
      this.playSvg = svgPlayWhite;
    },
    toggleModal() {
      this.isOpenModal = !this.isOpenModal;
    },
  },
};
</script>

<style scoped lang="scss">
.modalContainerVideo {
  @apply relative w-full flex flex-col items-center mt-[-80%] md:mt-[-70%] lg:mt-0 px-5 overflow-hidden;
}

.parallaxhero:deep() {
  @apply mx-8;
  .hero-background-parallax {
    @apply w-full max-w-screen-xl mx-auto !bg-cover !bg-fixed !bg-center mb-[80px] relative;
  }
  .title,
  .content {
    @apply w-full max-w-screen-xl mx-auto mb-[80px] max-w-[770px] text-center;
    p {
      @apply text-sm md:!text-lg !leading-[1.3em];
    }
  }
  .title {
    .text-title p {
      @apply text-lg sm:text-2xl md:!text-6xl  leading-[1.3em];
    }
  }

  strong {
    @apply text-[#5194D6];
  }

  .playButton {
    @apply w-[35px] h-[35px] right-[calc(50%-30px)] top-[calc(50%-10px)] md:w-[50px] md:h-[50px] absolute lg:w-[100px] lg:h-[100px] lg:right-[calc(50%-50px)] lg:top-[calc(50%-10px)] rounded-[300px] transition-all duration-300 ease-in-out;
    box-shadow: -3px 1px 40px 15px rgba(84, 82, 82, 0.8);
    &:hover {
      box-shadow: -3px 1px 40px 15px rgba(81, 148, 214, 0.4);
    }
  }
}

.heartbeat {
  animation: heartbeat 2s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
</style>