<template>
  <div class="Parallax">
    <div class="ParallaxCroma" />
    <div :style="styleImage" class="ParallaxContain">
      <div data-aos="zoom-in" data-aos-duration="1000" class="ParallaxTitle">
        {{ this.data.title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FullWidthParallax",
  // props: ["data"],
  mounted() {
    // console.log("Data", this.data.image.url);
  },
  props: {
    data: {
      acf_fc_layout: String,
      image: Object,
      title: String,
    },
  },
  computed: {
    styleImage() {
      return `background-image: url(${this.data.image.url})`;
    },
  },
};
</script>
<style scoped lang="scss">
.Parallax {
  @apply relative w-full;
}
.ParallaxTitle {
  @apply text-[30px] md:text-[36px] font-[400] max-w-screen-xl px-5 md:px-20 lg:px-24 font-Fraunces leading-[50px] text-center text-white z-1;
}

.ParallaxCroma {
  @apply absolute h-full bg-black w-full z-1 opacity-20;
}

.ParallaxContain {
  @apply flex items-center justify-center py-12 lg:py-5
  min-h-[850px] md:min-h-[750px] min-h-[600px] bg-fixed bg-center bg-cover relative bg-no-repeat;
}
</style>
