<template>
  <div class="containerVideo" @click="toggle" @touchstart="toggle">
    <vimeo-player :video-id="getVimeoStories" autoplay="true"></vimeo-player>
  </div>
</template>

<script>
export default {
  name: "ModalVideo",
  props: ["toggleModal"],
  components: {},
  data() {
    return {
      urlVideo: 123100654,
    };
  },
  methods: {
    toggle() {
      this.$emit("toggleModal");
    },
  },
  computed: {
    getVimeoStories() {
      const id =
        this.$store.getters.acfOptions()?.vimeo_stories_id || 123100654;
      return id;
    },
  },
};
</script>
<style scoped lang="scss">
.containerVideo:deep() {
  @apply w-[70%] md:w-[60%] lg:w-[45%] bg-transparent min-h-[400px] relative w-full;
  div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>