<template>
  <div class="menu-item-grid-nav">
    <div class="container-links-nav">
      <ul>
        <li
          :class="getMarginLi"
          v-for="(item, index) in menu"
          :key="item.content"
        >
          <RouterLink
            @mouseleave="hoverLinkNav(-1)"
            @mouseover="hoverLinkNav(index)"
            @click="toggle()"
            class="content-text-nav"
            :class="getRouteFontSize"
            :to="this.$root.convertToRelative(item.url)"
            :target="item.target"
          >
            {{ item.content }}
          </RouterLink>
        </li>
      </ul>
    </div>
    <div class="container-images-nav">
      <Transition
        name="fade"
        mode="out-in"
        duration="300"
        key="transitionMenuNav"
        appear
      >
        <img
          class="image-nav"
          :src="activeImage.url"
          :alt="activeImage.alt"
          :class="activeImage.boxShadowClass"
          v-if="activeImage.visible"
        />
      </Transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavMenu",
  data() {
    return {
      indexActiveImage: -1,
      activeImage: {
        visible: false,
        alt: "",
        url: "",
        boxShadowClass: "box-shadow-nav-0",
      },
      height: window.innerHeight,
    };
  },
  props: {
    menu: Array,
    toggle: Function,
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {


    getMarginLi() {
      const totalMenu = this?.menu?.length || 0;
      return totalMenu === 0 || totalMenu < 6 ? "mb-7" : "mb-4";
    },
    getRouteFontSize() {
      const screenH = this.height;
      const screenW = window.innerWidth;
      if(screenW>3000 && screenH>1000) {
        return "text-[80px] leading-[95px] md:text-[50px] md:leading-[70px]";
      }
      if (screenW < 1000) {
        return "text-[35px] leading-[50px] md:text-[50px] md:leading-[70px]";
      }
      if (screenH > 800) return "text-[70px] leading-[85px]";
      return screenH > 600
        ? "text-[50px] leading-[65px]"
        : "text-[35px] leading-[50px]";
    },
  },
  methods: {
    hoverLinkNav: function (index) {
      this.indexActiveImage = index;
      const boxShadowClassIndex = index;
      this.activeImage = {
        visible: index < 0 ? false : true,
        alt: this.menu[index]?.image?.alt || "",
        url: this.menu[index]?.image?.url || "",
        boxShadowClass: "box-shadow-nav-".concat(boxShadowClassIndex),
      };
    },
    onResize(e) {
      this.height = window.innerHeight;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.image-nav {
  @apply object-cover;
  min-height: 250px;
  max-height: 75vh;
}

.menu-item-grid-nav {
  @apply w-full pt-[22px] min-h-[calc(98vh-77px)] flex flex-col gap-5 mx-auto max-w-screen-xl px-5;
  @media only screen and (min-width: 800px) {
    @apply flex-row;
  }
  z-index: 1;
}

.container-links-nav {
  @apply self-start self-center mx-auto w-full lg:w-3/5;
}

.container-images-nav {
  @apply invisible min-h-[calc(99vh-77px)] w-full flex items-center justify-center flex-col lg:visible lg:w-2/5 mx-auto;
}

.box-shadow-nav-0 {
  box-shadow: 10px 10px 5px 0px rgba(209, 237, 176, 1);
}

.box-shadow-nav-1 {
  box-shadow: 10px 10px 5px 0px rgba(81, 148, 214, 1);
}

.box-shadow-nav-2 {
  box-shadow: 10px 10px 5px 0px rgba(156, 213, 193, 1);
}

.box-shadow-nav-3 {
  box-shadow: 10px 10px 5px 0px rgba(57, 74, 104, 1);
}

.box-shadow-nav-4 {
  box-shadow: 10px 10px 5px 0px rgb(176, 237, 203);
}

.box-shadow-nav-5 {
  box-shadow: 10px 10px 5px 0px rgb(81, 105, 214);
}

.content-text-nav {
  @apply font-Fraunces;
  font-style: normal;
  font-weight: 400;
  color: #d2d2d2;
  &:hover {
    color: #000000;
  }
}

.router-link-active {
  color: #000000;
}
</style>
