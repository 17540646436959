<template>
  <div class="dataCollapse">
    <div class="containerCollapse">
      <h5 class="titleCollapse">{{ title }}</h5>
      <button @click="toggleShowOptions" class="textButton group">
        <span> Learn More</span>
        <i v-if="!active" class="gg-chevron-right"></i>
        <i v-else-if="active" class="gg-chevron-down"></i>
      </button>
    </div>
    <div class="contentCollapse">
      <slide-up-down
        class="containerSlideDown"
        v-model="active"
        :duration="500"
      >
        <slot></slot>
      </slide-up-down>
    </div>
  </div>
</template>
<script>
import SlideUpDown from "vue3-slide-up-down";
export default {
  name: "Collapse",
  components: {
    SlideUpDown,
  },
  props: {
    title: {
      type: String,
      default: "lorem ipsun",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggleShowOptions() {
      this.active = !this.active;
    },
  },
};
</script>
<style lang="scss" scoped>
.dataCollapse {
  @apply w-full;
}

.textButton {
  @apply flex flex-row mb-5;
}

.textButton span {
  @apply hover:text-strongblue font-Avenir text-[16px] my-auto leading-[25px] md:leading-[40px] lg:leading-[78px];
}

.containerCollapse {
  @apply flex flex-row justify-between items-center border-b border-b-black gap-9;
}

.titleCollapse {
  @apply font-Fraunces font-[400] text-[28px] md:text-[50px] lg:text-[64px] mb-5 mt-0 leading-[40px] md:leading-[50px] lg:leading-[78px];
}
.containerSlideDown {
  @apply my-5;
}

.gg-chevron-down {
  @apply text-black transition-all duration-500 ease-in-out group-hover:text-strongblue my-auto;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-right {
  @apply text-black  transition-all duration-500 ease-in-out group-hover:text-strongblue my-auto;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}
</style>
