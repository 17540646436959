import { onMounted, ref, onUpdated } from "vue";
import { useStore } from "vuex";

const useGetBlogs = () => {
  const isSkeleton = ref(true);
  const isLoading = ref(true);
  const perPage = ref(6);
  const currentPage = ref(1);
  const totalPages = ref(1);
  const allBlogs = ref([]);
  const searchText = ref("");

  const store = useStore();

  const loadWithSearch = async (newVal) => {
    searchText.value = newVal;
    currentPage.value = 1;
    await store.dispatch("getItems", {
      type: "posts",
      params: {
        per_page: perPage.value,
        page: 1,
        orderby: "date",
        order: "desc",
        search: newVal,
      },
    });
    totalPages.value = store.getters.totalPages({
      type: "posts",
      params: {
        per_page: perPage.value,
        page: 1,
        orderby: "date",
        order: "desc",
        search: newVal,
      },
    });
    const newBlogs = store.getters.requestedItems({
      type: "posts",
      params: {
        per_page: perPage.value,
        page: 1,
        orderby: "date",
        order: "desc",
        search: newVal,
      },
    });
    allBlogs.value = newBlogs;
  };

  const loadMorePost = async () => {
    if (!isLoading.value) {
      isLoading.value = true;
      const nextPage = currentPage.value + 1;
      if (nextPage > totalPages.value) {
        totalPages.value = store.getters.totalPages({
          type: "posts",
          params: {
            per_page: perPage.value,
            page: 1,
            orderby: "date",
            order: "desc",
            search: searchText.value,
          },
        });
      } else {
        await store.dispatch("getItems", {
          type: "posts",
          params: {
            per_page: perPage.value,
            page: nextPage,
            orderby: "date",
            order: "desc",
            search: searchText.value,
          },
        });
        const newBlogs = store.getters.requestedItems({
          type: "posts",
          params: {
            per_page: perPage.value,
            page: nextPage,
            orderby: "date",
            order: "desc",
            search: searchText.value,
          },
        });
        allBlogs.value = [...allBlogs.value, ...newBlogs];
        currentPage.value = nextPage;
      }
      isLoading.value = false;
    }
  };

  onMounted(async () => {
    await store.dispatch("getItems", {
      type: "posts",
      params: {
        per_page: perPage.value,
        page: currentPage.value,
        orderby: "date",
        order: "desc",
      },
    });
    totalPages.value = store.getters.totalPages({
      type: "posts",
      params: {
        per_page: perPage.value,
        page: currentPage.value,
        orderby: "date",
        order: "desc",
      },
    });
    allBlogs.value = store.getters.requestedItems({
      type: "posts",
      params: {
        per_page: perPage.value,
        page: currentPage.value,
        orderby: "date",
        order: "desc",
      },
    });
    isLoading.value = false;
    isSkeleton.value = false;
  });

  return {
    isSkeleton,
    isLoading,
    blogs: allBlogs,
    loadMorePost,
    totalPages,
    loadWithSearch,
  };
};

export default useGetBlogs;
