<template>
  <div class="grid-form">
    <!-- <p class="title">{{ title }}</p> -->
    <p v-html="title" class="title" />

    <div class="flex flex-col w-auto mx-auto my-8">
      <button
        @click="toggleModal"
        class="
          font-Avenir
          bg-black
          border border-solid border-black
          rounded-[10px]
          w-auto
          mx-auto
          text-[16px] text-white
          py-4
          px-12
          transition-all
          duration-500
          ease-in-out
          hover:bg-white hover:text-black hover:border hover:border-black
        "
      >
        {{ buttonText }}
      </button>
    </div>

      <div class="grid-powered">
        <p class="font-Avenir">Powered by</p>
        <a
          class="font-Avenir"
          alt="Powered by"
          :src="poweredUrl"
          target="_blank"
        >
          <img
            class="imgExternal"
            :src="externalLogo.url"
            :alt="externalLogo.alt"
          />
        </a>
      </div>

    <UniversalModal v-model="activeModal" :close="toggleModal">
        <PopUpJoin
          :popUpLogo="popUpLogo"
          :popUpDescription="popUpDescription"
          :externalLink="externalLink"
          :poweredUrl="poweredUrl"
          :externalLogo="externalLogo"
          :closeModal="toggleModal"
          v-if="activeModal"
        />
    </UniversalModal>
  </div>
</template>

<script>
import { ref } from "vue";
import PopUpJoin from "./PopUpJoin.vue";

export default {
  name: "FormRender",
  components: {
    PopUpJoin,
  },
  props: {
    button_submit_text: String,
    form_id: String,
    powered_by_url: String,
    title: String,
    external_url_logo: Object,
    pop_up_logo: Object,
    contact_privacy_link: Object,
    pop_up_description: String,
    onSubmitAction: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const {
      button_submit_text,
      form_id,
      powered_by_url,
      title,
      external_url_logo,
      contact_privacy_link,
      pop_up_logo,
      pop_up_description
    } = props;

    const activeModal = ref(false);

    const toggleModal = () => {
      activeModal.value = !activeModal.value;
    };

    return {
      buttonText: button_submit_text || "Join a Clinical Trial",
      formId: form_id,
      poweredUrl: powered_by_url,
      title,
      externalLogo: external_url_logo,
      popUpLogo: pop_up_logo,
      externalLink: contact_privacy_link,
      popUpDescription: pop_up_description,
      activeModal,
      toggleModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-form {
  @apply flex flex-col gap-3 md:gap-7 w-full my-auto mx-auto;
}
.title:deep() p{
  @apply mx-auto text-black text-[30px] text-center leading-[45px] md:text-[35px] md:leading-[50px] font-Avenir;
}
.container-modal-form {
  @apply font-Avenir relative w-full flex flex-col items-center pr-12 lg:pr-0;
}

.button-submit {
  @apply bg-black rounded-[10px] w-auto mx-auto
    text-[200px] text-white py-4 px-16
    transition-all duration-500 ease-in-out
    hover:bg-white hover:text-black;
}

.grid-powered {
  @apply flex flex-row gap-3 mx-auto transition-all ease-in-out duration-500;
  p {
    @apply text-[16px] mb-0;
  }
  a {
    @apply text-[16px] mb-0 text-primary hover:cursor-pointer;
  }
}

.message-success {
  @apply text-[20px] mb-0 text-black mx-auto text-center font-Avenir mt-5;
}

.imgExternal {
  @apply max-w-[100px];
}
</style>