import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const userID = ref();
export default function useUUID() {
   
    const createUserID = () => {
        const ID = uuidv4()
        window.dataLayer.push({
            'sessionID' : ID
          })
        const storedID = sessionStorage.getItem("user_sesion")
        if (!storedID) {
            sessionStorage.setItem("user_session", ID);
            userID.value = ID
        } else {
            userID.value = storedID
        }
    }

    return { userID, createUserID }
}