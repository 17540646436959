<template>
  <div class="intro" ref="intro">
    <div class="intro-container">
      <div ref="animation" class="intro-animation"></div>
    </div>
  </div>
</template>

<script>
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json';
export default {
  data() {
    return {
    }
  },
  components: {
    LottieWeb
  },
  methods: {
  },
  mounted() {
    document.documentElement.classList.add("no-overflow")
    const { animation } = this.$refs
    const anim = LottieWeb.loadAnimation({
      container: animation,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: introJson
    })
    anim.onComplete = () => {
      document.documentElement.classList.remove('no-overflow')
      this.$root.introComplete()
    }
  },
  destroyed() {

    LottieWeb.destroy
  }
};
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-50 bg-gradient-to-tr from-pageLoadingfrom to-pageLoadingTo backdrop-blur-xl;
  .intro-container {
    @apply absolute top-0 left-0 flex items-center justify-center w-full h-full bg-cover bg-no-repeat relative top-[-50px];
    .intro-animation {
      @apply w-[60%] h-[60%] md:w-[400px] md:h-[400px] m-auto;
    }
  }
}
</style>