<template>
  <div class="wrapper" ref="wrapper">
    <main class="main">
      <section id="team-member-info">
        <div class="top-area">
          <div class="back-button"></div>
        </div>
        <div v-if="member && member.acf" class="team-member">
          <div class="team-picture">
            <img
              v-if="member.acf.teammate_data.teammate_bio.headshot.url"
              v-lazy="member.acf.teammate_data.teammate_bio.headshot.url"
              :alt="member.acf.teammate_data.teammate_bio.headshot.alt"
            />
            <div class="teammate-cta"></div>
          </div>
          <div class="team-bio">
            <div class="team-heading-area">
              <h2
                class="teammate-name text-primary"
              >{{ member.acf.teammate_data.teammate_info.name }}</h2>
              <div class="role-and-company">
                <p>
                  {{ member.acf.teammate_data.teammate_info.title }}
                  <template
                    v-if="member.acf.teammate_data.teammate_info.company && member.acf.teammate_data.teammate_info.company !== ''"
                  >at {{ member.acf.teammate_data.teammate_info.company }}</template>
                  <template
                    v-if="member.acf.teammate_data.teammate_info.portfolio && member.acf.teammate_data.teammate_info.portfolio !== ''"
                  >at {{ member.acf.teammate_data.teammate_info.portfolio }}</template>
                </p>
              </div>
            </div>
            <div class="teammate-bio-content" v-html="member.acf.teammate_data.teammate_bio.bio" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      request: {
        type: "team",
        slug: this.slug,
        showLoading: true,
      },
    }
  },
  computed: {
    member() {
      return this.$store.getters.singleBySlug(this.request);
    }
  },
  methods: {
    getMember() {
      this.$store.dispatch("getSingleBySlug", this.request).then(() => {
        if (this.member) {
          this.$store
            .dispatch("updateDocTitle", {
              parts: [
                this.$root.decodeTitle(this.member.title.rendered),
                this.$store.state.site.name,
              ],
            })
            .then(() => {
              this.$gtag.pageview({
                page_path: this.$root.convertToRelative(this.member.link),
                page_title: document.title,
                page_location: window.location.href,
              });
            });
        } else {
          this.$router.replace("/404");
          this.$gtag.pageview({
            page_path: "/404",
            page_title: document.title,
            page_location: window.location.href,
          });
        }
      });
    },
  },
  created() {
    this.getMember();
  },
  components: {
  },
  props: [
    'slug'
  ],
  mounted() {
    // console.log(this.member)
  }
}
</script>

<style lang='scss' scoped>
#team-member-info {
  @apply w-full mt-10 mb-40 p-4 xl:p-4;
  .top-area {
    @apply max-w-screen-xl mx-auto mb-10;
  }
  .team-member {
    @apply max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16;
    .team-picture {
      img {
        @apply object-cover object-center mx-auto max-h-[500px] md:max-h-max md:min-h-[80vh];
      }
      .teammate-cta {
        @apply grid grid-cols-1 lg:grid-cols-2 mt-10 lg:mt-15 gap-4;
        a {
          @apply mx-auto;
        }
      }
    }
    .team-bio {
      .team-heading-area {
        @apply border-solid border-gray-400 border-l-1 pl-5 max-w-[300px] mb-12;
        .teammate-name {
          @apply mb-4;
        }
      }
      .teammate-bio-content:deep() {
        @apply max-w-[90%];
        p {
          @apply !mb-7 text-text;
        }
      }
    }
  }
}
</style>