<template>
    <footer class="site-footer" v-if="!this.$store.state.site.loading">
      <div class="gutter-wrapper">
        <div class="footer-container">
          <div class="footer-head">
            <router-link :to="'/'" title="Home">
              <img
                v-if="acf_options.site_logo_footer"
                class="h-15"
                :src="acf_options.site_logo_footer.url"
                :alt="acf_options.site_logo_footer.alt"
              />
            </router-link>
          </div>
          <div class="footer-body">
            <div>
              <p class="rights text-[20px] leading-[2rem] font-light text-center lg:text-left">
                Â© FibroGen Copyrights Reserved.{{ currentYear }} - This is a US
                website
              </p>
              <p class="rights text-[20px] leading-[2rem] font-light text-center lg:text-left max-w-screen-md">
                Pamrevlumab is an investigational drug. The safety and efficacy of
                pamrevlumab in IPF patients has not been established. Pamrevlumab
                has not been approved by any regulatory agency for the treatment
                of any disease.
              </p>
              <a class="rights text-[20px] leading-[2rem] font-light text-center lg:text-left max-w-screen-md font-Avenir underline" href="https://www.fibrogen.com/copy-of-privacy-notice">Privacy policy</a>
            </div>
            <div class="social-media flex justify-start items-start p-y-4 gap-2">
              <a :href="acf_options.facebook" target="_blank">
                <img src="@/assets/img/facebookIcon.svg" alt="facebookIcon" />
              </a>
              <a
                v-if="acf_options.twitter"
                :href="acf_options.twitter"
                target="_blank"
              >
                <img src="@/assets/img/twitterIcon.svg" alt="twitterIcon" />
              </a>
              <a
                v-if="acf_options.instagram"
                :href="acf_options.instagram"
                target="_blank"
                ><img src="@/assets/img/instagramIcon.svg" alt="instagramIcon" />
              </a>
              <a
                v-if="acf_options.linkedin"
                :href="acf_options.linkedin"
                target="_blank"
                ><img src="@/assets/img/linkedInIcon.svg" alt="linkedInIconIcon"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // import FooterMenu from "@/components/template-parts/FooterMenu.vue";
  export default {
    components: {
      // FooterMenu,
    },
    data() {
      return {};
    },
    mounted() {
      // console.log("footer", this.$store.getters.acfOptions());
    },
    computed: {
      acf_options() {
        return this.$store.getters.acfOptions();
      },
      currentYear() {
        return new Date().getFullYear();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .site-footer {
    @apply w-full mx-auto bg-black text-offwhite;
  }
  .gutter-wrapper {
    @apply w-full max-w-screen-xl mx-auto;
  }
  
  .footer-container {
    @apply divide-y-1 divide-text max-w-screen-xl w-full py-5;
  }
  
  .footer-head {
    @apply flex flex-col items-center py-8 lg:flex-row lg:justify-between lg:py-9  lg:pb-7;
    .social-media {
      background-color: rgba(255, 255, 255, 0.8);
      img:hover {
        background-color: rgba(255, 255, 255, 0.262);
      }
    }
  }
  
  .footer-body {
    @apply text-text items-center flex flex-col text-[11px] items-center pt-4 pb-8 lg:flex-row lg:justify-between lg:pt-4 lg:pb-15;
    .rights {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  </style>