import axios from "axios";

export const getGeocodeAPI = async (direction, googleMapKey) => {
  const BASE_URI = "https://maps.googleapis.com/maps/api/geocode/json";
  return await axios.get(BASE_URI, {
    params: {
      address: direction,
      key: googleMapKey,
    },
  });
};

export const getGeometryLocation = (valAddress) => {
  const { data } = valAddress;
  const { results } = data;
  if (results.length) {
    return results[0].geometry.location;
  }
  return null;
};

export const getAddressArray = (valAddress) => {
  const { data } = valAddress;
  const { results } = data;
  if (results.length) {
    return results[0].address_components;
  }
  return null;
};

export const getFormatAddress = (address_components) => {
  let locationSearch = {
    zip: null,
    country: null,
    state: null,
    city: null,
  };
  address_components.forEach((val) => {
    for (let i = 0; i < val.types.length; i++) {
      switch (val.types[i]) {
        case "administrative_area_level_1":
          locationSearch.state = val.long_name;
          break;
        case "locality":
          locationSearch.city = val.long_name;
          break;
        case "country":
          locationSearch.country = val.long_name;
          break;
        case "postal_code":
          locationSearch.zip = val.long_name;
          break;
      }
    }
  });
  return locationSearch;
};

export const getGeoJsonLocation = async (ipGeoLocationKey) => {
  if (!ipGeoLocationKey) return null;
  const BASE_URI = "https://api.ipgeolocation.io/ipgeo";
  const geoJson = await axios.get(BASE_URI, {
    params: {
      apiKey: ipGeoLocationKey,
    },
  });
  const { data } = geoJson;
  if (!data) return null;
  if (!data?.country_name) return null;

  return {
    city: data?.city || null,
    country: data?.country_name || null,
    lat: data?.latitude || null,
    lng: data?.longitude || null,
  };
};
