<template >
    <div>
        <p>hola mundo {{data.titulo_1}}</p>
    </div>
</template>
<script>
export default {
    // data: {
    //     test: 'dsfdsfgdsg'
    // },
    props: ['data'],
    mounted() {
       // console.log(this.data)
    },
}
</script>
<style lang="scss" scoped>
    
</style>