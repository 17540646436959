<template>
  <section class="forms-section">
    <div class="form-group">
      <h2
        class="form-section-title text-primary text-center mb-10"
      >{{ data.forms_module_group.form_title }}</h2>
      <p
        :class="data.form_wide ? 'wide' : ''"
        class="form-section-text mb-15 text-center text-text mx-auto"
        v-html="data.form_text"
      />
      <div class="form-area">
        <FormKit
          id="formKit"
          type="form"
          @submit="submitHandler"
          :submit-label="submitButton"
          :disabled="disabled"
        >
          <FormKitSchema :schema="selectedForm" :library="library" />
        </FormKit>
      </div>
    </div>
  </section>
</template>

<script>
import { FormKitSchema } from '@formkit/vue'
import { reset } from '@formkit/core'
import Axios from "axios"

export default {
  data() {
    return {
      disabled: false,
      message: '',
      library: {}
    }
  },
  components: {
    FormKitSchema
  },
  computed: {
    selectedForm() {
      const schema = []
      const form = this.data.forms.find(x => x.id === this.data.forms_module_group.form_id)
      form.data.fields.forEach(field => {
        schema.push({
          $formkit: field.type === 'phone' ? 'tel' : field.type,
          name: field.label,
          // label: field.label,
          placeholder: field.placeholder,
          options: this.selectOptions(field.choices),
          classes: { outer: field.cssClass },
          validation: this.validationTypes(field.isRequired, field.type),
        })
      })
      schema.push({
        $el: 'div',
        children: this.message.replace(/<\/?[^>]+(>|$)/g, ""),
        attrs: {
          class: 'message-container mt-10 text-center text-green-500'
        }
      })
      return schema
    },
    submitButton() {
      const selectedForm = this.data.forms.find(x => x.id === this.data.forms_module_group.form_id)
      return `${selectedForm.data.button.text} →`

    },
  },
  methods: {
    submitHandler(data) {
      this.disabled = true
      const selectedForm = this.data.forms.find(x => x.id === this.data.forms_module_group.form_id)
      let curedForm = {}
      for (const field of Object.entries(data)) {
        curedForm[`input_${selectedForm.data.fields.find(x => x.label === field[0]).id}`] = field[1]
      }
      this.postData(selectedForm.submitURL, curedForm)
    },
    async postData(URL, body) {
      try {
        const response = await Axios.post(URL, body)
        this.message = response.data.confirmation_message
        reset('formKit')
        this.disabled = false
      } catch (error) {
        console.log(error);
      }
    },
    selectOptions(choices) {
      if (choices) {
        return choices.map(choice => {
          return choice.text
        })
      } else {
        return []
      }
    },
    validationTypes(required, type) {
      const validation = []
      if (required) {
        validation.push('required')
      }
      if (type === 'email') {
        validation.push('email')
      }
      if (type === 'phone') {
        validation.push('number')
        validation.push('length:10,10')
      }
      const finalValidation = validation.join('|')
      return finalValidation
    }
  },
  props: [
    "data"
  ],
  mounted() {
    //console.log(this.data)
  },
}
</script>

<style lang="scss" scoped>
.forms-section {
  @apply w-full max-w-screen-xl mx-auto py-10 lg:py-20 px-4 xl:px-0;

  .form-section-text:deep() {
    @apply max-w-2xl;
    a {
      @apply text-text underline underline-text;
    }
  }
  .form-section-text.wide {
    @apply max-w-screen-lg;
  }
  .form-area:deep() {
    @apply w-full;
    .formkit-form {
      @apply max-w-screen-xl;

      & * {
        @apply w-full;
      }
      input,
      textarea,
      select {
        @apply w-full !text-text font-publicSans opacity-80 focus:opacity-100 border-t-0 border-l-0 border-r-0 border-b-1 border-form text-form pb-4 mb-2 focus:shadow-none bg-transparent;
      }

      .formkit-actions {
        @apply text-center;
        button {
          @apply text-[16px] w-max font-light text-white bg-primary py-4 px-6 hover:bg-secondary transition transition-colors duration-200;
        }
      }
      .formkit-messages {
        @apply text-center;
        li {
          @apply text-red-400 pt-1 pr-3 mr-3;
        }
      }
      .form-element {
        @apply inline-block pr-0 md:pr-10 py-8;
        .formkit-messages {
          @apply text-left;
          li {
            @apply text-red-400 pt-1 pr-3 mr-3;
          }
        }
        &.half-width {
          @apply w-full md:w-1/2;
        }
        &.full-width {
          @apply w-full;
        }
        textarea {
          @apply min-h-40;
        }
        .formkit-messages {
          li {
            @apply absolute text-red-400 pt-1 pr-3 mr-3;
          }
          li:not(:last-child) {
            @apply border-r-1 border-red-300;
          }
        }
      }

      .gform_confirmation_message {
        @apply text-center text-green-500;
      }
      div[data-type="submit"] {
        @apply flex justify-center mt-10;
      }
      div[data-type="submit"] {
        button:disabled {
          @apply opacity-50 cursor-not-allowed;
        }
      }
    }
  }
}
</style>