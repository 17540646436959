<template>
  <div class="container-trail-locations-map">
    <h5 class="h5Title">Trial Locations</h5>
    <MapContainer :form_settings="form_settings" />
    <StudyLocations :form_settings="form_settings" />
  </div>
</template>

<script>
// store provide/inject
import { initStore } from "./composable/useStoreTrialMap";
// components
import MapContainer from "./MapComponent/MapContainer.vue";
import StudyLocations from "./StudyLocations/StudyLocations.vue";

export default {
  name: "TrialLocationsMap",
  components: {
    MapContainer,
    StudyLocations,
  },
  props: ["data"],
  setup(props) {
    const { trial_locations_map } = props.data;
    const { form_settings } = trial_locations_map;

    // init the provide/inject store
    initStore();
    return {
      form_settings,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-trail-locations-map {
  @apply mx-auto w-full max-w-screen-xl px-5 min-h-[750px] lg:px-0 flex flex-col gap-9 py-7;
}
.h5Title {
  @apply text-[25px] md:text-[50px] lg:text-[64px] font-Fraunces text-left 
  font-[400] leading-[40px] md:leading-[50px] lg:leading-[78px];
}
</style>
