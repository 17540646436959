<template>
  <div class="containerInfoColumn">
    <div class="column" v-html="data.column_1" />
    <div class="column" v-html="data.column_2" />
  </div>
</template>
<script>
export default {
  name: "ContentCollapse_3",
  props: ["data"],
  created() {
    // console.log("study_design", this.data);
  },
};
</script>
<style lang="scss" scoped>
.containerInfoColumn:deep() {
  @apply grid grid-cols-1 md:grid-cols-2 gap-15 md:gap-60 py-7;

  p {
    @apply font-Avenir md:text-[26px] pr-3;
    font-weight: 400;
    line-height: 39.16px !important;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 600 !important;
  }

  h3 {
    font-style: normal;
    line-height: 25px !important;
    font-weight: 600 !important;
    @apply font-Avenir mb-3 text-[20px] md:text-[26px];
  }

  .column:deep() {
    p {
      line-height: 39.16px !important;
      @apply font-Avenir text-[20px] md:text-[26px] leading-[35px] pr-3 pb-3;
    }
  }
}
</style>
