<template>
  <div class="wrapper" ref="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template v-if="module.acf_fc_layout === 'forms'">
            <Forms :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageModule'">
            <ImageComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'test'">
            <Test :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'Hero'">
            <Hero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'FullWidthParallax'">
            <FullWidthParallax :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'StoriesCarousel'">
            <StoriesCarousel :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ParalaxMaxHero'">
            <ParalaxMaxHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'aboutCard'">
            <AboutCard :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'FullScreenHero'">
            <FullScreenHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'StoriesResume'">
            <StoriesResume :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'JoinForm'">
            <JoinForm :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BlogGrid'">
            <BlogGrid :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'StoryHero'">
            <StoryHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BlogAllFilter'">
            <BlogAllFilter :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TrialLocationsMap'">
            <TrialLocationsMap :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'DataCollapse'">
            <DataCollapse :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TrialDetails'">
            <TrialDetails :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'CollapseFAQ'">
            <CollapseFAQ :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'SignUpHero'">
            <SignUpHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'HeroFAQ'">
            <HeroFAQ :key="index" :data="module" :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script>
import Forms from "@/components/modules/Forms.vue";
import ImageComponent from "@/components/modules/ImageComponent.vue";
import Test from "@/components/modules/Test.vue";
import Hero from "@/components/modules/Hero/Hero.vue";
import FullWidthParallax from "@/components/modules/FullWidthParallax/FullWidthParallax.vue";
import FullScreenHero from "./modules/FullScreenHero.vue";
import StoriesCarousel from "@/components/modules/StoriesCarousel/StoriesCarousel.vue";
import ParalaxMaxHero from "./modules/ParalaxMaxHero.vue";
import AboutCard from "@/components/modules/AboutCard/AboutCard.vue";
import StoriesResume from "@/components/modules/StoriesResume/StoriesResume.vue";
import JoinForm from "@/components/modules/JoinForm/JoinForm.vue";
import BlogGrid from "@/components/modules/BlogGrid.vue";
import StoryHero from "./modules/StoryHero/StoryHero.vue";
import BlogAllFilter from "@/components/modules/BlogAllFilter/BlogAllFilter.vue";
import TrialLocationsMap from "./modules/TrialLocationsMap/TrialLocationsMap.vue";
import DataCollapse from "@/components/modules/DataCollapse/DataCollapse.vue";
import SignUpHero from "./modules/SignUpHero/SignUpHero.vue";
import CollapseFAQ from "@/components/modules/CollapseFAQ/CollapseFAQ.vue";
import TrialDetails from "./modules/TrialDetails/TrialDetails.vue";
import HeroFAQ from "@/components/modules/HeroFAQ/HeroFAQ.vue";

export default {
  components: {
    Forms,
    ImageComponent,
    Test,
    Hero,
    FullWidthParallax,
    FullScreenHero,
    StoriesCarousel,
    ParalaxMaxHero,
    AboutCard,
    StoriesResume,
    JoinForm,
    BlogGrid,
    StoryHero,
    BlogAllFilter,
    TrialLocationsMap,
    DataCollapse,
    SignUpHero,
    CollapseFAQ,
    TrialDetails,
    HeroFAQ,
  },
  name: "Page",
  props: {
    slug: {
      type: String,
      required: true,
    },
    isHomepage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      request: {
        type: "pages",
        slug: this.slug,
        showLoading: true,
      },
      introShown: false,
    };
  },
  computed: {
    page() {
      this.normalizeData("forms");
      // console.log('this.$store.getters.singleBySlug(this.request) ', this.$store.getters.singleBySlug(this.request))
      return this.$store.getters.singleBySlug(this.request);
    },
  },
  methods: {
    getPage() {
      this.$store
        .dispatch("getSingleBySlug", this.request)
        .then(() => {
          if (this.page) {
            if (!this.isHomepage) {
              this.$store.dispatch("updateDocTitle", {
                parts: [
                  this.$root.decodeTitle(this.page.title.rendered),
                  this.$store.state.site.name,
                ],
              });
            } else {
              this.$store.dispatch("updateDocTitle", {
                parts: [null, this.$store.state.site.name],
              });
            }
          } else {
            this.$router.replace("/404");
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.page.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        });
    },
    normalizeData(type) {
      if (type) {
        const filtered = this.$store.getters.singleBySlug(this.request);
        const rawData = this.$store.getters.getPosts({ type: type });
        if (filtered) {
          for (const module of filtered.acf.modules) {
            if (module.acf_fc_layout === type) {
              const data = Object.entries(rawData).map((x) => x[1]);
              data.pop();
              module[type] = data;
            }
          }
        }
      }
    },
    introComplete() {
      this.introShown = true;
      sessionStorage.setItem("introShown", true);
    },
  },
  created() {
    this.getPage();
  },
  mounted() {
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  },
};
</script>

<style lang="scss"></style>
