<template>
  <div class="containerCollapse">
    <div class="collapseFAQ" @click="toggleShowOptions">
      <p class="titleCollapse">
        {{ dataCollapse.titleitem }}
      </p>
      <Transition name="fade" mode="out-in" :duration="300">
        <span v-if="!active" class="iconCollapse"> + </span>
        <span v-else-if="active" class="iconCollapse"> - </span>
      </Transition>
    </div>
    <SlideUpDown v-model="active" :duration="350">
      <div class="ContainerContent">
        <div v-html="dataCollapse.contentItem" class="contendCollapse" />
      </div>
    </SlideUpDown>
  </div>
</template>
<script>
import SlideUpDown from "vue3-slide-up-down";
import { ref } from "vue";
export default {
  name: "CollapseComponent",
  props: ["data"],
  components: {
    SlideUpDown,
  },
  setup(props) {
    const active = ref(false);
    const dataCollapse = props.data;
    // console.log(dataCollapse);
    const toggleShowOptions = () => {
      active.value = !active.value;
    };

    return {
      dataCollapse,
      active,
      toggleShowOptions,
    };
  },
};
</script>
<style scoped lang="scss">
.containerCollapse {
  @apply mb-3 w-full bg-[#F8F8F8] rounded-lg;
}
.collapseFAQ {
  @apply flex flex-row justify-between items-center hover:bg-[#DBDBDB] rounded-lg py-2 px-5 cursor-pointer;
}
.iconCollapse {
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  @apply w-full max-w-[16px] font-Avenir text-center;
}
.ContainerContent {
  @apply my-3 p-4;
}
.contendCollapse:deep() {
  p {
    font-weight: 400;
    @apply font-Avenir text-[18px] md:text-[20px] !leading-[25px] md:!leading-[30px];
  }
}
.titleCollapse {
  font-weight: 400;
  @apply font-Fraunces text-[22px] md:text-[26px] leading-[28px] md:leading-[36px];
}
</style>
