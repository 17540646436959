<template>
  <div class="parallax-container">
    <div class="parallax-croma" />
    <div
      class="full-screen-hero"
      :style="`background-image: url('${data.background_image.url}')`"
    >
      <div class="inner-container">
        <h1 class="title" data-aos="fade-up" data-aos-duration="500">
          {{ data.title }}
        </h1>
        <p
          class="content"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="300"
        >
          {{ data.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  mounted() {
    //console.log(this.data)
  },
};
</script>

<style scoped lang="scss">
.parallax-container {
  @apply relative w-full h-[calc(100vh+90px)] -top-[90px] -z-1 flex flex-col;
  max-height: 1300px !important;
  margin-bottom: -90px !important;
  @media only screen and (max-width: 395px) {
    height: calc(50vh);
  }
  @media only screen and (max-width: 769px) {
    height: calc(70vh);
  }
}
.full-screen-hero {
  @apply bg-fixed bg-no-repeat bg-bottom bg-cover h-full;
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 395px) {
    @apply bg-center bg-local;
  }
  @media only screen and (max-width: 769px) {
    @apply bg-center bg-local;
  }
  .inner-container {
    @apply w-full h-full max-w-screen-xl mx-auto px-12 md:px-6 xl:px-0 flex flex-col justify-center items-center max-h-[900px] mb-auto;
    h1 {
      @apply text-[30px] md:text-[70px] xl:text-[144px] mb-5 md:mb-8 lg:mb-10 font-Fraunces text-center text-white break-words;
    } 
    p {
      @apply text-center text-white text-[16px] md:text-[24px] lg:text-[26px] font-Avenir break-words;
    }
  }
}

.parallax-croma {
  @apply absolute h-full bg-black w-full opacity-30;
}
</style>