<template>
  <div class="container-images" data-aos="zoom-in" data-aos-duration="700">
    <div
      class="card-images"
      v-for="(
        {
          image: { alt, url: urlImage },
          link: { title, url: urlLink, target },
        },
        index
      ) in imagelist"
      :key="index"
    >
      <img class="images" :src="urlImage" :alt="alt" />
      <div class="gradient" :class="'gradient-hero-image-' + index" />
      <div class="links">
        <a :href="urlLink" :target="target" :title="urlLink">{{ title }} </a>
        <img class="vector-arrow" src="@/assets/img/hero-vector-arrow.svg" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imagelist: [Object],
  },
  mounted() {
    //console.log("props ", this.imagelist);
  },
  computed: {
    descriptionClass: () => {
      return {
        "text-primary": true,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.container-images {
  @apply mx-auto 
  w-full
  lg:rounded-[20px] md:overflow-hidden
  relative grid grid-cols-1 sm:grid-cols-2;
}
.card-images {
  @apply w-full flex flex-col relative;
  min-height: 200px;
  max-height: 50vh;
  overflow: hidden;
  .images {
    @apply transition transition-all ease-in-out duration-500;
  }
  .gradient {
    @apply transition transition-all ease-in-out duration-500;
  }
  &:hover {
    overflow: hidden;
    .images {
      @apply transition transition-all ease-in-out duration-500;
      transform: scale(1.05);
    }
    .gradient {
      @apply transition transition-all ease-in-out duration-500;
      transform: scale(1.5);
      opacity: 0.5;
    }
  }
}
.images {
  @apply w-full mx-auto my-auto h-full object-cover;
}

.gradient {
  @apply w-full absolute h-full;
}

.links:deep() {
  @apply flex flex-row w-full absolute bottom-0 mx-8 mb-8 font-bold text-white text-[36px] break-words w-[80%] text-left;
  a {
    width: min-content;
  }
}

.vector-arrow {
  @apply ml-3 mt-auto bottom-0 font-bold text-white text-[27px] text-right;
}

.gradient-hero-image-0 {
  background: linear-gradient(
    41.69deg,
    #d1edb0 0%,
    rgba(209, 237, 176, 0) 101.21%
  );
}
.gradient-hero-image-1 {
  background: linear-gradient(
    41.53deg,
    rgba(81, 148, 214, 0.8) 0%,
    rgba(81, 148, 214, 0) 100%
  );
}
.gradient-hero-image-2 {
  background: linear-gradient(
    41.67deg,
    rgba(156, 213, 193, 0.8) 0%,
    rgba(156, 213, 193, 0) 100%
  );
}
.gradient-hero-image-3 {
  background: linear-gradient(
    41.53deg,
    rgba(57, 74, 104, 0.8) 0%,
    rgba(57, 74, 104, 0) 100%
  );
}
</style>
