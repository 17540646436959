<template>
  <div class="container-content"
  data-aos="fade-up"
      data-aos-duration="700"
  >
    <div class="subtitle">
      <span>{{ subtitle }}</span>
    </div>
    <div class="title" v-html="title" />
    <div class="descripcion">{{ description }}</div>
  </div>
</template>
<script>
export default {
  props: {
    description: String,
    subtitle: String,
    title: String,
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.container-content {
  @apply w-full flex flex-col mx-auto justify-center lg:max-w-[75%]
}
.subtitle:deep() {
  @apply mb-7 mt-7;
  * {
      @apply text-primary md:mt-0 uppercase;
      font-size: 16px;
      font-weight: 400;
  }
}

.title:deep() {
  @apply mb-7;
  * {
    @apply text-[40px] leading-[60px] text-black mb-0 
    font-bold break-words max-w-full lg:text-[56px] lg:leading-[70px];
    strong {
      @apply text-primary;
    }
  }
}

.descripcion {
  font-size: 20px;
  font-weight: 400;
}
</style>
