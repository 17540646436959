<template>
  <div
    class="hamburger"
    @click="toggle"
    :class="isOpen ? 'hamburger--is-open' : ''"
  >
    <div
      :style="hamburgerWhite && !isOpen ? 'background: white' : ''"
      class="hamburger__item hamburger__item--first w-full"
    ></div>
    <div
      :style="hamburgerWhite && !isOpen ? 'background: white' : ''"
      class="hamburger__item hamburger__item--middle w-3/4"
    ></div>
    <div
      :style="hamburgerWhite && !isOpen ? 'background: white' : ''"
      class="hamburger__item hamburger__item--last w-full"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["isOpen", "toggle", "hamburgerWhite"],
};
</script>

<style lang="scss" scoped>
.hamburger {
  @apply flex flex-col justify-between;
  height: 25px;
  width: 30px;

  &:hover {
    cursor: pointer;
  }

  &__item {
    border-radius: 20px;
    height: 5px;
    background: black;
    transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
      opacity 300ms linear;

    &--first {
      .hamburger--is-open & {
        transform: translate(0px, 10px) rotate(45deg) scale(1.15);
      }
    }

    &--middle {
      .hamburger--is-open & {
        opacity: 0;
      }
    }

    &--last {
      .hamburger--is-open & {
        transform: translate(0px, -10px) rotate(-45deg) scale(1.15);
      }
    }
  }
}
</style>
