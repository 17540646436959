<template>
  <div class="blog-grid-container">
    <div class="animate-pulse flex flex-col gap-8" v-if="isSkeleton">
      <div class="w-flex flex flex-row gap-5">
        <div class="w-[150px] lg:w-[350px] bg-slate-200 h-[48px]" />
        <div class="w-[100px] lg:w-[100px] bg-slate-200 h-[48px] ml-auto" />
      </div>
      <div class="inner-container">
        <div
          v-for="i of new Array(3).fill(0)"
          :key="i"
          class="blog-item"
          data-aos="fade-right"
          data-aos-duration="500"
          :data-aos-delay="200 * i"
        >
          <div class="overlay"></div>
        </div>
      </div>
    </div>
    <div v-else-if="!isSkeleton" class="relative">
      <div class="overlayLoading" v-if="isLoading"></div>
      <div class="container-search">
        <div class="relative flex w-[300px] flex-wrap items-stretch mb-3">
          <span class="icon-search">
            <img src="@/assets/img/search-vector.svg" />
          </span>
          <input
            @input="onChangeInput"
            type="text"
            placeholder="Search Keywords"
            class="input-search"
          />
        </div>
      </div>
      <div class="inner-container" v-if="blogs.length > 0">
        <div
          v-for="(blog, i) of blogs"
          :key="i"
          class="blog-item"
          :style="`background-image: url('${blog.featured_image.large}')`"
          data-aos="fade-right"
          data-aos-duration="500"
          :data-aos-delay="200 * i"
        >
          <a :href="blog.link" target="_self">
            <div class="overlay" :style="`background: ${getColor(i)};`">
              <p class="blog-date">{{ formatDate(blog.date) }}</p>
              <h3 class="blog-title" v-html="blog.title.rendered" />
              <p class="category">{{ blog.categories_info[0].name }}</p>
              <img src="@/assets/img/double-chevron.svg" class="select-arrow" />
            </div>
          </a>
        </div>
      </div>
      <div
        v-if="blogs.length > 0"
        class="flex flex-row"
        data-aos="fade-in"
        data-aos-duration="800"
      >
        <button class="outter-button" @click="loadMorePost">
          <p v-if="isLoading">LOADING ...</p>
          <p v-else-if="!isLoading">LOAD MORE</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useGetBlogs from "./composable/useGetBlogs";
import { FormKit } from "@formkit/vue";
export default {
  props: ["data"],
  components: {
    FormKit,
  },
  setup() {
    const { isLoading, isSkeleton, blogs, loadMorePost, loadWithSearch } =
      useGetBlogs();

    const formatDate = (date) => {
      return (
        new Date(date).getDate() +
        "." +
        new Date(date).getMonth() +
        "." +
        new Date(date).getFullYear()
      );
    };
    const recursiveGetColor = (i) => {
      const aux = i - 3;
      if (aux < 3) return aux;
      else return recursiveGetColor(aux);
    };
    const getColor = (x) => {
      const colors = [
        "rgba(57, 74, 104, 0.9)",
        "rgba(81, 148, 214, 0.9)",
        "rgba(156, 213, 193, 0.9)",
      ];
      if (x < 3) {
        return colors[x];
      } else {
        return colors[recursiveGetColor(x)];
      }
    };

    const onChangeInput = (e) => {
      loadWithSearch(e.target.value);
    };

    return {
      isSkeleton,
      isLoading,
      blogs,
      loadMorePost,
      formatDate,
      getColor,
      onChangeInput,
    };
  },
};
</script>

<style scoped lang="scss">
.blog-grid-container {
  @apply w-full max-w-screen-xl mx-auto px-7 lg:px-0 py-12 lg:py-16 min-h-[400px];

  .overlayLoading {
    @apply w-full h-full bg-gray-200 absolute z-1 bg-opacity-[0.40];
  }

  .container-search {
    @apply font-Avenir w-full flex flex-row gap-5 mb-5 items-center justify-between;
    .icon-search {
      @apply z-1 h-full leading-snug font-normal absolute text-center text-slate-300 absolute
              bg-transparent rounded text-base items-center justify-center w-7 pl-3 py-3;
    }
    .input-search {
      @apply font-Avenir border border-[#BCBCBC] rounded-[10px]
              placeholder-slate-300
              relative
              bg-white bg-white
              pl-10;
    }
    strong {
      font-weight: 700;
    }
  }
  .inner-container {
    @apply gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 z-0;
    .blog-item {
      @apply w-full h-[470px] bg-cover bg-center bg-no-repeat text-white relative bg-slate-200;
      .overlay {
        @apply absolute top-0 left-0 w-[75%] h-[75%] p-6 flex flex-col justify-between transition-all duration-300 ease-in-out cursor-pointer;
        &:hover {
          @apply w-full h-full transition-all duration-300 ease-in-out;
        }
        .blog-date {
          @apply text-xs font-light;
        }
        h3 {
          @apply font-medium text-[28px] leading-snug;
        }
        .category {
          @apply text-xs font-light transform rotate-180;
          writing-mode: vertical-lr;
        }
        .select-arrow {
          @apply w-6 h-auto absolute bottom-[24px];
        }
      }
    }
  }
  .outter-button {
    @apply w-max rounded-md bg-black text-white mt-10 text-center mx-auto border-1 
    border-solid border-black hover:bg-white px-12 py-2
    hover:text-black transition-colors duration-300 ease-in-out;
  }
}
</style>