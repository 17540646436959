<template>
  <div
    class="container-content-stories-resume"
    data-aos="fade-left"
    data-aos-duration="700"
    data-aos-delay="500"
  >
    <div v-html="title" class="title" />
    <p class="description">{{ description }}</p>
    <a :target="url.target" :href="url.url" class="button-url">
      View All Stories
    </a>
  </div>
</template>

<script>
export default {
  name: "ContentStoriesResume",
  props: {
    description: String,
    title: String,
    redirect_url: Object,
  },
  setup(props) {
    const { description, title, redirect_url: url } = props;
    return {
      description,
      title,
      url,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-content-stories-resume {
  @apply w-full h-auto flex flex-col gap-7 my-auto lg:pl-12;
}
.title:deep() {
  * {
    @apply text-[40px] leading-[50px] 
    md:leading-[70px] md:text-[60px] 
    lg:text-[64px] lg:leading-[78px]
    font-Fraunces mb-0;
    font-weight: 400;
    strong {
      @apply text-strongblue;
    }
  }
}
.description {
  @apply text-[20px] leading-[30px] font-Avenir;
  font-weight: 400;
}

.button-url {
  @apply bg-black rounded-[10px] w-auto mx-auto border border-black
    text-[16px] text-white py-4 px-16
    lg:mr-auto lg:ml-0
    transition-all duration-500 ease-in-out
    hover:bg-white hover:text-black;
}
</style>