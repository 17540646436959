export default {
  menu:
    (state) =>
    ({ name }) => {
      return state.menus[name];
    },
  request:
    (state) =>
    ({ type, params }) => {
      return state[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          return Object.keys(req.params).every(
            (key) => req.params[key] === params[key]
          );
        }
      });
    },
  getPosts:
    (state) =>
    ({ type, params }) => {
      return state[type];
    },
  totalPages:
    (state, getters) =>
    ({ type, params }) => {
      const request = getters.request({ type, params });
      return request ? request.totalPages : 0;
    },
  requestedItems:
    (state, getters) =>
    ({ type, params }) => {
      const request = getters.request({ type, params });
      return request ? request.data.map((id) => state[type][id]) : [];
    },
  singleByType: (state) => (type) => {
    return state[type] || null;
  },
  singleBySlug:
    (state) =>
    ({ type, slug }) => {
      for (const id in state[type]) {
        if (decodeURI(state[type][id].slug) === slug) {
          return state[type][id];
        }
      }
    },
  singleById:
    (state) =>
    ({ type, id }) => {
      return state[type][id];
    },
  searchTerm: (state) => () => {
    return state.search_term;
  },
  acfOptions: (state) => () => {
    return state.acf_options;
  },
};
