<template>
  <div
    class="container-images-stories-resume"
    data-aos="fade-right"
    data-aos-duration="700"
  >
    <div class="container-images-grid">
      <div v-for="(item, index) in imageList" :key="index" class="item-image">
        <img class="image" :class="useFilter" :alt="item.alt" :src="item.url" />
        <div v-if="useFilter === 'filter-gray'" class="hover-transition" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "ImagesStoriesResume",
  props: ["imageList"],
  setup(props) {
    const imageList = props.imageList.reduce((prev, current) => {
      prev.push(current.image_file);
      return prev;
    }, []);
    const useFilter = computed(() => "filter-gray");
    return {
      imageList,
      useFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-images-stories-resume {
  @apply w-full h-auto content-center flex flex-col relative
 min-h-[650px] 
 justify-items-center;
}
.container-images-grid {
  @apply grid grid-cols-1 md:grid-cols-2 my-auto mx-auto;
}

.item-image {
  @apply w-full flex flex-col relative min-h-[200px] max-h-[320px] overflow-hidden;
}

.image {
  @apply object-cover h-full w-full;
}

.filter-gray {
  filter: grayscale(100%);
}

.hover-transition {
  @apply w-full h-full absolute bg-[rgba(255,255,255,0)] 
 hover:bg-[rgba(255,255,255,0.1)] transition-all duration-300 ease-in-out;
}
</style>