<template>
  <div
    class="container-join-form"
    :class="getParallaxImage.class"
    :style="getParallaxImage.style"
    data-aos="zoom-in"
    data-aos-duration="500"
  >
    <div class="container-components" :class="getContainerFormOrientation">
      <div
        class="container-content"
        :class="getContainerContentOrientation"
        data-aos="fade-right"
        data-aos-duration="700"
        data-aos-delay="600"
      >
        <ContentJoinForm
          v-bind="{ ...content, usingParallax: getParallaxImage.usingParallax }"
        />
      </div>
      <div
        class="container-form"
        data-aos="fade-left"
        data-aos-duration="700"
        data-aos-delay="600"
      >
        <FormRender v-bind="formSettings" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentJoinForm from "./ContentJoinForm.vue";
import FormRender from "./FormRender.vue";
import { computed } from "vue";
export default {
  name: "JoinForm",
  props: ["data"],
  components: {
    ContentJoinForm,
    FormRender,
  },
  setup(props) {
    const { data } = props;
    const {
      content,
      form_settings,
      parallax_background,
      notUseParallax = false,
    } = data;

    const getParallaxImage = computed(() => {
      if (notUseParallax) {
        return {
          usingParallax: false,
          class: "",
          style: "",
        };
      }
      return {
        usingParallax: !!parallax_background.url,
        class: parallax_background.url ? "parallax-config" : "",
        style: parallax_background.url
          ? `background-image: url(${parallax_background.url})`
          : "background-image: none",
      };
    });

    const getContainerFormOrientation = computed(() => {
      const { layout_orientation } = form_settings;
      return layout_orientation.toLowerCase() === "left"
        ? "flex-col-reverse lg:flex-row-reverse"
        : "flex-col lg:flex-row";
    });

    const getContainerContentOrientation = computed(() => {
      const { layout_orientation } = form_settings;
      return layout_orientation.toLowerCase() === "left"
        ? "px-7 lg:pl-36 lg:pr-0"
        : "px-7 lg:pr-36 lg:pl-0";
    });

    return {
      content,
      formSettings: form_settings,
      getParallaxImage,
      getContainerFormOrientation,
      getContainerContentOrientation,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-join-form {
  @apply relative min-h-[700px] max-h-[2000px] lg:max-h-[1400px] bg-white flex flex-col py-12 lg:py-40;
}

.parallax-config {
  @apply bg-fixed bg-cover;
}

.container-components {
  @apply my-auto mx-auto w-full max-w-screen-xl 
  flex gap-16 lg:gap-7 px-7 py-7 lg:px-0 lg:py-0;
}

.container-content {
  @apply w-full flex flex-col min-h-[350px] lg:w-3/5;
}

.container-form {
  @apply w-full rounded-[10px] flex flex-col py-8 px-5 md:py-12 md:py-8 mx-auto lg:mx-0
  bg-white my-auto min-h-[300px] lg:w-2/5 max-w-[500px];
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.15);
}
</style>