<template>
  <section class="storyhero">
    <div class="container">
      <div class="content">
        <div class="story-background-parallax">
          <img
            v-if="renderTagImage"
            class="quote-top"
            :src="`${Quote}`"
            alt=""
          />
          <div class="image-container">
            <img class="image" :src="`${data.image.url}`" alt="" />
          </div>
        </div>
        <div class="container-title">
          <div class="subtitle">
            <div class="text-subtitle" v-html="data.subtitle" />
          </div>
          <div class="title">
            <div class="text-title" v-html="data.title" />
          </div>
          <div class="cont">
            <div class="text-content" v-html="data.content" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Quote from "@/assets/img/Quote.svg";

export default {
  props: ["data", "renderTagImage"],
  data() {
    return {
      Quote,
    };
  },
};
</script>

<style scoped lang="scss">
.storyhero:deep() {
  @apply mx-7 mt-9 mb-7 md:my-12 lg:my-20;

  .container {
    @apply w-full mx-auto max-w-screen-xl;
  }

  .story-background-parallax {
    @apply w-full max-w-screen-xl mx-auto relative;

    .quote-top {
      @apply absolute max-w-[250px] h-[140px] top-[-75px] left-[calc(50%_-_75px)];
    }

    .image-container {
      @apply mx-auto flex flex-col w-full max-h-[800px] min-h-[400px] md:max-w-[500px] lg:min-w-[550px] lg:max-w-[600px];
    }

    .image {
      @apply mx-auto my-auto bg-cover bg-no-repeat max-h-[800px] min-h-[300px] md:min-w-[400px] md:min-h-[550px] md:max-w-[600px];
    }

    .icon {
      @apply absolute mix-blend-multiply opacity-[0.8] top-[-90px] left-0 right-0 m-auto;
    }
  }

  .content {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-12 container mx-auto items-center;
  }

  .container-title {
    @apply p-0 lg:pl-3;
  }

  .title .text-title {
    @apply my-[30px] text-[32px] md:text-[50px] lg:text-[64px] lg:text-left text-center font-Fraunces font-400 !leading-[35px]  md:!leading-[60px] lg:!leading-[70px] lg:pr-[14%];
  }

  .subtitle .text-subtitle p {
    @apply text-[22px] lg:text-left text-center font-Avenir;
  }

  .subtitle .text-subtitle strong {
    @apply text-[#5194D6];
  }

  .cont .text-content p {
    @apply text-[20px] !leading-[1.3em] text-left font-Avenir mb-2;
  }
}
</style>
