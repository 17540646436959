<template>
  <div class="wrapper">
    <main>
      <div class="top-area mb-10 px-6 lg:px-0"></div>
      <div v-if="post">
        <StoryHero
          :data="{
            image: {
              url: post.featured_image.large,
            },
            subtitle: `<p><strong>${post.categories_info[0].name}</strong> - ${getDate}</p>`,
            title: post.title.rendered,
            content: post.excerpt.rendered,
            deleteImageTag: false,
          }"
        />
      </div>
      <article v-if="post" class="single-post">
        <div class="content px-6 lg:px-0" v-html="post.content.rendered" />
      </article>
      <BlogGrid
        :data="{
          blog_grid: {
            title: 'Recent Posts',
            button: {
              url: '/blog',
              target: false,
              title: 'View All',
            },
          },
        }"
      />
    </main>
    <div v-if="dataJoinForm">
      <JoinForm v-if="dataJoinForm" :data="dataJoinForm" />
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
// compontes
import StoryHero from "@/components/modules/StoryHero/StoryHero.vue";
import BlogGrid from "@/components/modules/BlogGrid.vue";
import JoinForm from "@/components/modules/JoinForm/JoinForm.vue";

export default {
  name: "Single",
  components: {
    StoryHero,
    BlogGrid,
    JoinForm,
  },
  props: {
    slug: {
      type: String,
      required: false,
    },
    newsSlug: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      request: {
        type: "posts",
        slug: this.slug,
        showLoading: true,
      },
      relatedPosts: [],
      dataJoinForm: null,
    };
  },
  computed: {
    post() {
      return this.$store.getters.singleBySlug(this.request);
    },
    async readingTime() {
      let words = (await this.post.content.rendered.split(" ").length) + 1;
      return `${Math.ceil(words / 200)} min read`;
    },
    getDate: {
      get() {
        const { date } = this.post;
        const newDate =
          new Date(date).getDate() +
          " . " +
          new Date(date).getMonth() +
          " . " +
          new Date(date).getFullYear();
        return newDate;
      },
      default: "Loading...",
    },
  },
  methods: {
    sortData(data) {
      return {
        info_card_headline: data.title.rendered
          ? data.title.rendered
          : "Larry H. Miller",
        info_card_content: data.excerpt.rendered
          ? data.excerpt.rendered
          : "Larry H. Miller",
        card_image: { url: data.featured_image.medium },
        info_card_date: data.date,
        info_card_cta_button: { title: "Read more", url: data.link },
      };
    },
    getPost() {
      this.$store
        .dispatch("getSingleBySlug", this.request)
        .then(() => {
          if (this.post) {
            this.$store.dispatch("updateDocTitle", {
              parts: [
                this.$root.decodeTitle(this.post.title.rendered),
                this.$store.state.site.name,
              ],
            });

            this.date = format(new Date(this.post.date), "d MMMM yyyy");

            const relatedRequest = {
              type: "posts",
              params: {
                categories: this.post.categories,
                exclude: this.post.id,
                per_page: 3,
                fields:
                  "id,slug,title,featured_media,link,author,categories,excerpt,better_featured_image,date,modified,content",
              },
              showLoading: false,
            };

            this.$store.dispatch("getItems", relatedRequest).then(() => {
              this.relatedPosts =
                this.$store.getters.requestedItems(relatedRequest);
            });
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.post.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        });
    },
    getDataJoinForm() {
      const dataJoinForm =
        this.$store.getters.acfOptions()?.stories_call_to_action;
      this.dataJoinForm = dataJoinForm;
    },
  },
  created() {
    this.getPost();
    this.getDataJoinForm();
  },
  updated() {
    //console.log(this.getDate());
    // console.log(this.relatedPosts);
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
main:deep() {
  @apply w-full max-w-screen-xl mx-auto my-10;

  .single-post {
    @apply prose max-w-full pt-7 pb-16;

    .content {
      @apply max-w-[840px] mx-auto;
    }

    .figcaption {
      @apply text-center;
    }

    p {
      @apply text-black;
    }

    strong {
      @apply text-black;
    }

    img {
      @apply my-3;
    }

    a {
      @apply text-primary underline-primary;
    }

    ol,
    ul {
      li {
        @apply text-black!text-[20px] font-light !leading-[30px];

        strong {
          @apply text-black;
        }

        p {
          @apply !text-[20px] font-thin;

          b {
            @apply !text-[20px] font-normal;
          }
        }
      }
      @apply text-black;
    }

    ul > li::before {
      @apply bg-black;
    }

    .wp-block-gallery {
      @apply mx-0;

      .blocks-gallery-grid {
        @apply flex;

        .blocks-gallery-item {
          @apply w-[33%] p-0 m-0;

          &::before {
            @apply hidden;
          }

          figure {
            @apply m-0 p-2 w-full h-full;

            img {
              @apply object-cover h-full w-full;
            }
          }
        }
      }
    }

    .has-text-align-center {
      @apply text-center;
    }

    .has-text-align-right {
      @apply text-right;
    }

    .has-text-align-left {
      @apply text-left;
    }

    figure.wp-block-image {
      @apply m-0 py-12;
    }

    .are-vertically-aligned-center,
    .is-vertically-aligned-center {
      @apply flex items-center flex-col;
    }

    figure.wp-block-gallery {
      @apply flex flex-wrap my-0;

      &.columns-7 {
        figure {
          width: calc(14.28% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-0 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-0 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-6 {
        figure {
          width: calc(16.5% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-0 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-0 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-5 {
        figure {
          width: calc(20% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-0 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-0 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-4 {
        figure {
          width: calc(25% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-100 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-100 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-3 {
        figure {
          width: calc(33.33% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-100 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-100 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-2 {
        figure {
          width: calc(50% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-100 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-100 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-1 {
        figure {
          width: calc(100% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-100 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-100 text-white transition-opacity duration-300 z-9;
          }
        }
      }

      &.columns-default {
        figure {
          width: calc(33.33% - 16px * 0.66667);
          margin: 4px;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          position: relative;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;

          img {
            @apply object-cover w-full h-full;
          }

          figcaption {
            @apply absolute opacity-0 transition-opacity duration-300 w-[95%] h-[95%] top-2 left-2;
          }
        }

        figure:before {
          @apply content-[""] w-full h-full bg-black/50 absolute left-0 top-0 opacity-0 transition-opacity duration-300;
        }

        figure:hover:before {
          @apply opacity-100 transition-opacity duration-300;
        }

        :hover {
          figcaption {
            @apply opacity-100 text-white transition-opacity duration-300 z-9;
          }
        }
      }
    }

    blockquote {
      @apply text-left border-none w-full mx-auto p-0 !text-[#5194D6];

      p {
        @apply !text-[#5194D6] text-black my-8 lg:my-10 text-left !text-[20px] !leading-[30px] lg:!text-[24px] lg:!leading-[35px] pl-0 not-italic font-Fraunces;
      }

      strong {
        @apply !text-[#5194D6] text-black my-8 lg:my-10 text-center !text-[20px] !leading-[30px] lg:!text-[24px] lg:!leading-[35px] pl-0 not-italic font-Fraunces;
      }

      ::before,
      ::after {
        @apply hidden;
      }

      cite {
        @apply text-primary text-center mb-10 not-italic !text-[20px];

        p {
          @apply text-primary text-center mb-10 not-italic !text-[20px];
        }
      }
    }

    .quote-ref {
      @apply text-primary text-center mb-10;
    }

    .wp-block-columns {
      @apply flex flex-col lg: flex-row gap-0 lg:gap-6 mb-4;

      .wp-block-column {
        @apply w-full;
      }
    }

    .wp-block-column:not(:first-child) {
      @apply mt-8 lg: mt-0;
    }

    .wp-block-video {
      @apply w-full lg:  !w-[80%] !my-10 !mx-auto;

      video {
        @apply w-full;
      }
    }

    video {
      @apply w-full;
    }

    .title-area {
      @apply grid grid-cols-1 lg: grid-cols-5 border-b-1 border-gray-300;

      .title-container {
        @apply col-span-4;

        h1 {
          @apply font-light text-[32px] mb-4;
        }

        p {
          @apply text-black;
        }
      }

      .social-media {
        @apply col-span-1 flex flex-row justify-end h-max;

        a {
          img {
            filter: brightness(0);
          }
        }
      }
    }

    .content {
      @apply mb-10 mt-10 lg: mb-30 lg:mt-10;
      p {
        font-style: normal;
        font-weight: 400;
        @apply text-[16px] leading-[26px] lg:text-[20px] lg:leading-[30px] font-Avenir;
      }
    }

    h1 {
      font-size: 55px;
      font-weight: 300;
      line-height: 109.5%;
      letter-spacing: -0.03em;
    }

    h2 {
      font-size: 32px;
      line-height: 35px;
      font-weight: 300;
      letter-spacing: -0.03em;
      @apply text-black;
      strong {
        @apply text-black;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 107.5%;
      letter-spacing: -0.02em;
      font-weight: 300;
    }

    p {
      @apply text-black mt-0 text-black;
      strong {
        @apply text-black;
      }
    }
  }
}
</style>
