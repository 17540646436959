<template>
  <div class="container-Trial-Detail">
    <h3 data-aos="zoom-in" data-aos-duration="500" class="titleTrialDetails">
      {{ data.title }}
    </h3>
    <div
      data-aos="zoom-in"
      data-aos-duration="500"
      class="infoTrialDetails"
      v-html="data.description"
    />
    <div class="grdTrialDetails">
      <div
        data-aos="fade-up"
        data-aos-duration="500"
        v-for="(item, index) in data.facts"
        :key="index"
        class="flex flex-col justify-center"
      >
        <h4 class="textTrial">{{ item.title }}</h4>
        <p class="textTrial textFact">{{ item.fact_data }}</p>
      </div>
    </div>
    <div data-aos="fade-up" data-aos-duration="1000">
      <div class="button" data-aos="fade-in" data-aos-duration="800">
        <div class="outter-button">
          <a :href="data.link_buton.url" :target="data.link_buton.target">
            <div class="button-inner">
              <p>{{ data.link_buton.title }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrialDetails",
  props: ["data"],
  mounted() {
    //  console.log("TrialDetails", this.data);
  },
};
</script>
<style lang="scss" scoped>
.container-Trial-Detail:deep() {
  @apply max-w-screen-xl h-full  w-full px-7 lg:px-0 mx-auto py-24 flex flex-col justify-center items-center;
  .infoTrialDetails p:first-child {
    line-height: 35.52px !important;
    @apply text-[2opx]  md:text-[26px] font-Avenir font-normal pb-8 max-w-screen-lg text-center;
  }
  .infoTrialDetails p span {
    @apply text-strongblue;
  }
}
.titleTrialDetails {
  @apply font-Fraunces font-normal text-[32px] md:text-[64px] leading-[79px] text-center pb-9;
}
.descriptionDetails {
  @apply;
}

.grdTrialDetails {
  @apply px-5 py-5 gap-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full;
}
.textTrial {
  @apply font-Avenir;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}
.textFact {
  @apply mb-2 font-medium font-Fraunces;
  font-size: 30px !important;
  line-height: 50px;
}
.outter-button {
  @apply w-max rounded-md bg-black text-white mt-10 text-center mx-auto border-1 border-solid border-black hover:bg-white hover:text-black transition-colors duration-300 ease-in-out;
  a {
    @apply w-full h-full;
    .button-inner {
      @apply w-full h-full px-12 py-2;
    }
  }
}
</style>
