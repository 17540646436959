<template>
  <div class="container-stories-resume">
    <Images :imageList="imageList" />
    <Content v-bind="content" />
  </div>
</template>

<script>
import Content from "./ContentStoriesResume.vue";
import Images from "./ImagesStoriesResume.vue";
export default {
  name: "StoriesResume",
  props: ["data"],
  components: {
    Content,
    Images,
  },
  setup(props) {
    const { data } = props;
    return {
      content: data?.content || {},
      imageList: data?.image_list || [],
    };
  },
};
</script>

<style lang="scss" scoped>
.container-stories-resume {
  @apply w-full mx-auto py-12 lg:py-24 px-7 max-w-screen-xl min-h-[200px]
 grid grid-cols-1 gap-7 lg:grid-cols-2 lg:px-0;
}
</style>