<template lang="">
  <div class="singlestories" v-if="singleData">
    <StoryHero
      :data="{
        image: singleData.acf.personal_data.image,
        subtitle: `<strong>${singleData.title.rendered} asfasfasfasf</strong> - ${singleData.acf.personal_data.status}`,
        title: singleData.acf.content.quote,
        content: singleData.acf.content.content,
      }"
      :renderTagImage="true"
    />
    <div
      class="contentsinglestories"
      v-if="singleData.content.rendered"
      v-html="singleData.content.rendered"
    />

    <div class="contentcarouselstories">
      <h2 class="contentsinglestories-title">More Stories</h2>
      <StoriesCarousel />
    </div>

    <div v-if="dataJoinForm">
      <JoinForm :data="dataJoinForm" />
    </div>
  </div>
</template>
<script>
import StoryHero from "@/components/modules/StoryHero/StoryHero.vue";
import StoriesCarousel from "@/components/modules/StoriesCarousel/StoriesCarousel.vue";
import JoinForm from "@/components/modules/JoinForm/JoinForm.vue";

export default {
  props: ["slug"],
  components: {
    StoryHero,
    StoriesCarousel,
    JoinForm,
  },
  created() {
    this.dispatchStory();
  },
  methods: {
    dispatchStory() {
      return this.$store.dispatch("getSingleBySlug", {
        type: "stories",
        slug: this.slug,
        showLoading: true,
      });
    },
  },
  updated() {
    //console.log("singleData", this.singleData);
  },
  computed: {
    singleData() {
      return this.$store.getters.singleBySlug({
        type: "stories",
        slug: this.slug,
      });
    },
    dataJoinForm() {
      const dataJoinFornm =
        this.$store.getters.acfOptions().stories_call_to_action;
      return {
        ...dataJoinFornm,
        notUseParallax: true,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.singlestories:deep() {
  .contentcarouselstories {
    .contentsinglestories-title {
      @apply font-Fraunces font-400 text-[64px] !leading-[79px] text-center my-15;
    }
  }
  .contentsinglestories {
    @apply w-full mx-auto px-7 md:px-0 md:max-w-[770px];

    blockquote {
      @apply text-[#5194D6] m-0 my-[20px] font-400 !leading-[30px];

      p {
        @apply !font-Fraunces !leading-[35px] text-[24px] mb-10;
      }
    }

    p {
      @apply font-Avenir font-400 !leading-[30px] text-[20px] mb-10;
    }

    img {
      @apply w-full m-auto py-10;
    }
  }
}
</style>
