<template>
  <div class="container-collapse">
    <Collapse :title="data.study_details.title">
      <ContentCollapse_1 :data="data.study_details.facts" />
    </Collapse>
    <Collapse :title="data.study_description.title">
      <ContentCollapse_2 :data="data.study_description.content" />
    </Collapse>
    <Collapse :title="data.study_design.title">
      <ContentCollapse_3 :data="data.study_design" />
    </Collapse>
    <Collapse :title="data.eligibility_criteria.title">
      <ContentCollapse_4 :data="data.eligibility_criteria.content_list" />
    </Collapse>
  </div>
</template>
<script>
import Collapse from "./Collapse.vue";
import ContentCollapse_1 from "./ContentCollapse_1.vue";
import ContentCollapse_2 from "./ContentCollapse_2.vue";
import ContentCollapse_3 from "./ContentCollapse_3.vue";
import ContentCollapse_4 from "./ContentCollapse_4.vue";
export default {
  name: "DataCollapse",
  props: ["data"],
  components: {
    Collapse,
    ContentCollapse_1,
    ContentCollapse_2,
    ContentCollapse_3,
    ContentCollapse_4,
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.container-collapse {
  @apply max-w-screen-xl w-full px-7 2xl:px-0 mx-auto pt-7 flex flex-col gap-7;
}
</style>
