<template>
  <div class="w-full" v-if="!!getStories">
    <swiper
      :slidesPerView="3.5"
      :centeredSlides="true"
      :spaceBetween="0"
      :autoplay="{
        delay: 3500,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :grabCursor="true"
      :loop="true"
      :breakpoints="breakpoints"
      :modules="modules"
      v-if="getStories.length > 0"
    >
      <swiper-slide
        class="!m-0 w-full lg:!w-[485px]"
        v-for="(item, index) in getStories"
        :key="index"
      >
        <div class="carousel__item">
          <div class="carucelContainer">
            <div class="grupcard group">
              <div class="grupItem">
                <div class="containerInfo">
                  <img
                    class="imageIcon"
                    src="@/assets/img/Group.svg"
                    alt="storiesIcon"
                  />
                  <div class="titleCarucel">
                    <h5>
                      {{ item.content.quote }}
                    </h5>
                  </div>
                  <RouterLink :to="$root.convertToRelative(item.link)">
                    <div class="containerRouter">
                      <div class="contentName">
                        <span class="name">{{ item.title }}</span>
                        <span class="name">{{
                          item.personal_data.status
                        }}</span>
                      </div>
                      <img
                        class="containerArrow"
                        src="@/assets/img/Group6.svg"
                        alt="storiesIconarrow"
                      />
                    </div>
                  </RouterLink>
                </div>
              </div>
            </div>
            <img
              class="imageStorie"
              :src="item.personal_data.image"
              :alt="'ImageStoy_' + index"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
// import required modules
import { Pagination, Autoplay } from "swiper";
// vuex
import { useStore } from "vuex";
import { computed, onMounted } from "vue";

export default {
  name: "StoriesCarouselVue",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const globalStore = useStore();

    const getStories = computed(() => {
      const stories = globalStore.getters.requestedItems({
        type: "stories",
        params: {
          page: 1,
          per_page: 50,
          orderby: "date",
          order: "desc",
        },
      });

      if (!stories) return null;

      const finalData = stories.map((item) => {
        const data = {
          link: item.link,
          title: item.title.rendered,
          content: {
            brief: item.acf.content.brief,
            quote: item.acf.content.quote,
          },
          personal_data: {
            status: item.acf.personal_data.status,
            image: item.acf.personal_data.image.url,
          },
        };
        return data;
      });

      return finalData;
    });

    onMounted(async () => {
      await globalStore.dispatch("getItems", {
        type: "stories",
        params: {
          page: 1,
          per_page: 50,
          orderby: "date",
          order: "desc",
        },
      });
    });

    const breakpoints = {
      250: {
        slidesPerView: 1,
        // snapAlign: "center",
      },
      // 700px and up
      700: {
        slidesPerView: 1.5,
        // snapAlign: "center",
      },
      // 1024 and up
      1024: {
        slidesPerView: 2.5,
        // snapAlign: "center",
      },
      // 1200 and up
      1200: {
        slidesPerView: 3.5,
        // snapAlign: "start",
      },
    };

    if (getStories) {
      console.log("getStories", getStories);
    }

    return {
      getStories,
      breakpoints,
      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<style scoped lang="scss">
.carousel__item {
  @apply !w-full !h-full;
}

.titleCarucel:deep() {
  @apply h-full flex justify-center items-end;
  h5 {
    font-style: normal;
    font-weight: 800;
    @apply h-full !max-h-[208px] font-Avenir mb-[100px] md:mb-[80px] text-[36px] !leading-[52px] overflow-y-auto;
  }
}

.carucelContainer {
  @apply h-[534px] 2xl:h-full w-full relative filter grayscale hover:grayscale-0;
}

.name {
  @apply font-Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.grupcard {
  @apply p-8 absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.4)] lg:bg-[rgba(255,255,255,0)] lg:hover:bg-[rgba(255,255,255,0.7)] transition-all duration-300 ease-in-out;
}

.containerInfo {
  @apply h-full w-full flex flex-col justify-between;
}

.imageIcon {
  @apply w-16 h-auto;
}

.imageChevronRight {
  @apply w-5 h-auto;
}

.containerArrow {
  @apply flex items-center justify-end;
}

.grupItem {
  @apply h-full w-full opacity-100 lg:opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out;
}

.imageStorie {
  @apply object-cover h-full w-full;
}

.containerRouter {
  @apply flex flex-row justify-between items-center gap-4 mt-4;
}

.contentName {
  @apply w-full flex flex-col gap-2 text-left;
}
</style>
