<template>
  <div class="containerInfo">
    <div class="infoCollapse" v-html="data" />
  </div>
</template>
<script>
export default {
  name: "ContentCollapse_2",
  props: ["data"],
};
</script>
<style lang="scss" scoped>
.containerInfo:deep() {
  @apply py-12;
  .infoCollapse p {
    line-height: 30.12px !important;
    @apply pb-5 text-[15px] md:text-[20px] font-Avenir;
  }
  .infoCollapse p:first-child {
    line-height: 35.52px !important;
    @apply text-[20px] md:text-[26px] font-Avenir pb-8 md:pb-14;
  }

  .infoCollapse p strong span {
    @apply text-strongblue;
  }
}
</style>
