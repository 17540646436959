<template>
  <div class="blog-grid-container">
    <h2>{{ data.blog_grid.title }}</h2>
    <div class="inner-container" v-if="blogs.length > 0">
      <div
        v-for="(blog, i) of blogs"
        :key="i"
        class="blog-item"
        :style="`background-image: url('${blog.featured_image.large}')`"
        data-aos="fade-right"
        data-aos-duration="500"
        :data-aos-delay="200 * i"
      >
        <a :href="blog.link" target="_self">
          <div
            class="overlay"
            :style="`background: ${getColor(i, blogs.length)};`"
          >
            <p class="blog-date">{{ formatDate(blog.date) }}</p>
            <h3 class="blog-title" v-html="blog.title.rendered" />
            <p class="category">{{ blog.categories_info[0].name }}</p>
            <img src="@/assets/img/double-chevron.svg" class="select-arrow" />
          </div>
        </a>
      </div>
    </div>
    <div class="button" data-aos="fade-in" data-aos-duration="800">
      <div class="outter-button">
        <a
          :href="data.blog_grid.button.url"
          :target="data.blog_grid.button.target"
        >
          <div class="button-inner">
            <p>{{ data.blog_grid.button.title }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  created() {
    this.getBlogs();
  },
  computed: {
    blogs() {
      return this.$store.getters.requestedItems({
        type: "posts",
        params: {
          per_page: 3,
          page: 1,
          order: "desc",
        },
      });
    },
  },
  updated() {
    // console.log(this.data)
  },
  methods: {
    formatDate(date) {
      return (
        new Date(date).getDate() +
        "." +
        new Date(date).getMonth() +
        "." +
        new Date(date).getFullYear()
      );
    },
    getColor(x, lastNum) {
      const patternOfThree = (x % lastNum) + 1;
      if (patternOfThree === 1) {
        return "rgba(57, 74, 104, 0.9)";
      } else if (patternOfThree === 2) {
        return "rgba(81, 148, 214, 0.9)";
      } else {
        return "rgba(156, 213, 193, 0.9)";
      }
    },
    getBlogs() {
      return this.$store.dispatch("getItems", {
        type: "posts",
        params: {
          per_page: 3,
          page: 1,
          order: "desc",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.blog-grid-container {
  @apply w-full max-w-screen-xl mx-auto px-7 lg:px-0 py-12 lg:py-16;
  h2 {
    @apply text-center font-Fraunces font-normal text-[64px] mb-16;
  }
  .inner-container {
    @apply flex gap-8 flex-col lg:flex-row;
    .blog-item {
      @apply w-full h-[470px] bg-cover bg-center bg-no-repeat text-white relative;
      .overlay {
        @apply absolute top-0 left-0 w-[75%] h-[75%] p-6 flex flex-col justify-between transition-all duration-300 ease-in-out cursor-pointer;
        &:hover {
          @apply w-full h-full transition-all duration-300 ease-in-out;
        }
        .blog-date {
          @apply text-xs font-light;
        }
        h3 {
          @apply font-medium text-[28px] leading-snug;
        }
        .category {
          @apply text-xs font-[400] transform rotate-180 font-Avenir text-[12px];
          writing-mode: vertical-lr;
        }
        .select-arrow {
          @apply w-6 h-auto absolute bottom-[24px];
        }
      }
    }
  }
  .outter-button {
    @apply w-max rounded-md bg-black text-white mt-10 text-center mx-auto border-1 border-solid border-black hover:bg-white hover:text-black transition-colors duration-300 ease-in-out;
    a {
      @apply w-full h-full;
      .button-inner {
        @apply w-full h-full px-12 py-2;
      }
    }
  }
}
</style>