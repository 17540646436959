import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import "virtual:windi.css";
import { plugin, defaultConfig } from "@formkit/vue";
import smoothscroll from "smoothscroll-polyfill";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import googleMapConfig from "./googleMapConfig";
import VueUniversalModal from "vue-universal-modal";
import vueVimeoPlayer from "vue-vimeo-player";
import VueGtag from "vue-gtag";

import "vue-universal-modal/dist/index.css";

// kick off the polyfill!
smoothscroll.polyfill();

export const Application = createApp(App);

Application.use(router)
  .use(plugin, defaultConfig)
  .use(store)
  .use(VueGoogleMaps, googleMapConfig)
  .use(VueUniversalModal, {
    teleportTarget: "#fibrogen-modals",
    modalComponent: "UniversalModal",
  })
  .use(vueVimeoPlayer)
  .use(VueGtag, {
    config: { id: "GTM-PCJVLFN" }
  })
  .mount("#app");
router.beforeEach((to, from, next) => {
  next();
});
