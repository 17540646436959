import { createStore } from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const { state } = window.__VUE_WORDPRESS__
const finalState = Object.assign(state, { cardIndex: 0 })

const finalMutations = Object.assign(mutations, {
  incrementIndex (state) {
    state.cardIndex++
  },
  resetIndex (state) {
    state.cardIndex = 0
  }
})

export default createStore({
  state: finalState,
  getters,
  mutations: finalMutations,
  actions
})
