<template>
  <section class="hero_faq">
    <div class="content">
      <div class="image-container">
        <img
          class="image"
          :src="`${dataHeroFAQ.imagen_hero}`"
          alt="Imagen_Hero_FAQ"
        />
      </div>
      <div class="container-title">
        <div class="title">
          <div class="text-title">
            <strong>
              <p v-html="dataHeroFAQ.contend_hero.title" />
            </strong>
          </div>
        </div>
        <div class="cont">
          <div class="text-content">
            <p v-html="dataHeroFAQ.contend_hero.description" />
          </div>
        </div>
      </div>
    </div>
    <div class="containerContend">
      <div class="cont">
        <div class="text-content" v-html="dataHeroFAQ.contend" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "HeroFAQ",
  props: ["data"],
  setup(props) {
    const dataHeroFAQ = props.data;
    console.log("dataHeroFAQ", dataHeroFAQ);
    return {
      dataHeroFAQ,
    };
  },
};
</script>
<style scoped lang="scss">
.hero_faq:deep() {
  @apply max-w-screen-xl w-full px-7 2xl:px-0 mx-auto py-7;

  .content {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-12 container xl:mx-auto items-center;
  }
  .image-container {
    @apply mx-auto flex flex-col w-full max-h-[800px] min-h-[400px] md:max-w-[500px] lg:min-w-[550px] lg:max-w-[600px];
  }
  .image {
    @apply mx-auto my-auto bg-cover bg-no-repeat max-h-[800px] min-h-[300px] md:min-w-[400px] md:min-h-[550px] md:max-w-[600px];
  }
  .container-title {
    @apply p-0 lg:pl-3;
  }

  .title .text-title p {
    @apply my-[30px] text-[32px] md:text-[50px] lg:text-[64px] lg:text-left text-center font-Fraunces font-400 !leading-[35px]  md:!leading-[60px] lg:!leading-[70px] lg:pr-[14%];
  }

  .title .text-title strong {
    @apply text-[#5194D6];
  }

  .content {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-12 container mx-auto items-center;
  }
  .cont .text-content p {
    @apply text-[20px] !leading-[1.3em] text-left font-Avenir mb-8 text-justify;
    strong {
      font-weight: 600 !important;
    }
  }

  .containerContend {
    @apply w-full max-w-screen-md mx-auto lg:my-12;
    blockquote {
      @apply mx-0 !text-[#5194D6];
    }
  }
}
</style>
