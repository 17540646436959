<template>
  <div class="container-about-card" :class="getFlexOrientation.containerModule">
    <div
      class="container-links"
      :class="getFlexOrientation.containerLinks"
      data-aos="fade-right"
      data-aos-duration="500"
    >
      <div
        :v-if="description !== ''"
        class="description"
        v-html="description"
      />
      <div
        class="container-item-link"
        v-for="(item, index) in getLinks"
        :key="index"
      >
        <span class="item-text">{{ item.title }}</span>
        <a class="learn-more-link" :href="item.url" :target="item.target"
          >Learn More</a
        >
      </div>
    </div>
    <div class="container-image" data-aos="fade-right" data-aos-duration="1000">
      <img
        class="image"
        :src="image.url"
        :alt="image.alt"
        :class="getFlexOrientation.image"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: ["data"],
  setup(props) {
    const {
      data: { description, image, links },
    } = props;
    const { layout_orientation, url } = image;

    const getFlexOrientation = computed(() => {
      return {
        containerModule:
          layout_orientation.toLowerCase() === "left"
            ? "reverse-card"
            : "normal-card",
        image:
          layout_orientation.toLowerCase() === "left"
            ? "mx-auto lg:mr-auto lg:ml-0"
            : "mx-auto lg:ml-auto lg:mr-0",
        containerLinks:
          layout_orientation.toLowerCase() === "left"
            ? "px-0 lg:pl-24 lg:pr-0"
            : "px-0 lg:pr-42 lg:pl-0",
      };
    });

    const getLinks = computed(() => {
      return links.map((item) => {
        return {
          target: item.about_link?.target || "",
          title: item.about_link?.title || "",
          url: item.about_link?.url || "",
        };
      });
    });

    return {
      getFlexOrientation,
      description: description || "",
      getLinks,
      image: url,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-about-card {
  @apply px-7 flex flex-col max-w-screen-xl bg-transparent
   min-h-[300px] mx-auto py-20 lg:flex-row lg:px-0 gap-7;
}

.normal-card {
  @apply flex-col lg:flex-row;
}
.reverse-card {
  @apply flex-col-reverse lg:flex-row-reverse;
}
.container-image {
  @apply flex w-full lg:w-3/7;
}

.image {
  @apply align-middle max-w-[600px] max-h-[800px] object-cover w-full h-full my-auto;
}

.container-links {
  @apply flex flex-col w-full content-center py-7 self-center align-middle
  items-center gap-7 lg:w-4/7 md:py-12;
}

.description:deep() {
  @apply mb-7;
  * {
    @apply break-words text-black mx-auto mb-0 font-bold text-[40px] 
    leading-[50px] md:text-[60px] md:leading-[70px] lg:text-[64px] lg:leading-[78px]
    font-Fraunces;
    font-weight: 400;
    strong {
      @apply text-strongblue;
    }
  }
}

.container-item-link {
  @apply border border-t-0 border-x-0 
    border-b-black w-full flex flex-row justify-between;
}
.item-text {
  @apply ml-5 text-[20px] leading-[40px] md:text-[40px] md:leading-[70px] lg:text-[32px] lg:leading-[43px];
  font-weight: 400px;
}

.learn-more-link {
  @apply mt-auto mr-3 mb-3 cursor-pointer hover:text-primary 
   text-[14px] leading-[20px] 
   md:text-[32px] md:leading-[43px] 
   lg:text-[16px] lg:leading-[20px];
  font-weight: 400px;
  line-height: 20px;
}
</style>