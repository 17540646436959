<template>
  <header ref="header" class="header-nav" :class="classFixedHeader">
    <nav
      :class="navWhite && !navOpen ? 'container-nav-White' : 'container-nav'"
    >
      <div class="w-full">
        <a href="/">
          <img class="h-[34px] w-auto" :src="validateLogo()" />
        </a>
      </div>
      <div class="menu-nav">
        <a class="signup-nav" href="/sign-up">Sign Up</a>
        <div class="my-auto">
          <NavIcon
            :hamburgerWhite="navWhite"
            :isOpen="navOpen"
            :toggle="ToggleNav"
          />
        </div>
      </div>
    </nav>
    <Transition name="fade">
      <div class="container-menu-item" v-if="navOpen">
        <NavMenuItem :menu="menu" :toggle="ToggleNav" />
      </div>
    </Transition>
  </header>
</template>

<script>
import { Transition } from "vue";
import NavMenuItem from "./NavMenuItem.vue";
import NavIcon from "./NavIcon.vue";
import useUUID from '../../../useComposable';
const { createUserID } = useUUID()
export default {
  name: "NavMenu",
  data() {
    return {
      menu: this.$store.getters.menu({ name: "main-menu" }),
      acf: this.$store.getters.acfOptions(),
      navOpen: false,
      navWhite: false,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    ToggleNav() {
      return (this.navOpen = !this.navOpen);
    },
    ChangeNav() {
      return (this.navOpen = !this.navOpen);
    },
    validateLogo() {
      if (!this.navWhite && !this.navOpen) {
        return this.acf.site_logo?.url;
      } else {
        if (this.navOpen) {
          return this.acf.site_logo.url;
        } else {
          return this.acf.site_logo_footer?.url;
        }
      }
    },
    validationNavColor() {
      this.navWhite = false;
      const { page_color } = this.$store.getters.acfOptions();
      if (page_color) {
        let currentPage = this.$router.currentRoute.value.href;
        if (page_color.length > 0) {
          if (currentPage === "/") {
            currentPage = "home";
          }
          if (currentPage) {
            for (let page of page_color) {
              if (page === `${this.$store.state.site.url}/`) {
                page = "home";
              }
              if (this.$root.convertToRelative(page).includes(currentPage)) {
                this.navWhite = true;
                return;
              } else {
                this.navWhite = false;
              }
            }
          }
        }
      }
    },
  },
  computed: {
    classFixedHeader() {
      return {
        fixedHeader: this.navOpen,
      };
    },
  },
  watch: {
    navOpen(newVal) {
      if (newVal)
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      else document.getElementsByTagName("body")[0].style.overflow = "auto";
    },
    $route(to, from) {
      try {this.validationNavColor();} catch (e) {console.log(e)}
    },
  },
  mounted() {
    createUserID()
    this.validationNavColor();
  },
  created() {
    // console.log('menu ', this.menu)
    this.unwatch = this.$store.watch(
      (state, getters) => getters.singleByType("site"),
      (newValue, oldValue) => {
        //console.log(`Updating from ${oldValue} to ${newValue}`);
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  components: {
    NavMenuItem,
    Transition,
    NavIcon,
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  @apply bg-transparent py-[22px] bg-transparent transition-all duration-400 delay-40 ease-in;
}
.container-nav {
  @apply bg-transparent px-7 mx-auto w-full flex flex-row max-w-screen-xl lg:px-5 xl:px-0;
}

.container-nav-White {
  @apply text-white bg-transparent px-7 mx-auto w-full flex flex-row max-w-screen-xl lg:px-5 xl:px-0;
}

.menu-nav {
  @apply w-full flex flex-row justify-end content-center justify-items-end;
}

.signup-nav {
  @apply hover:underline font-bold mr-7 my-auto ml-auto font-Avenir;
}

.container-menu-item {
  @apply px-7 w-full mx-auto bg-white lg:px-0 h-full;
  z-index: 9999;
}

.fixedHeader {
  @apply w-full bg-white transition-all duration-400 delay-85 ease-out;
  z-index: 9999;
}

.a_SignUp {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #000000;
}
</style>
