<template>
  <div class="container-collapse-1">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="flex flex-col justify-center"
    >
      <p class="textCollapse">{{ item.title }}</p>
      <h4 class="textFact textCollapse">
        {{ item.fact }}
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentCollapse_1",
  props: ["data"],
};
</script>
<style lang="scss" scoped>
.container-collapse-1 {
  @apply px-5 py-5 lg:py-12 gap-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full;
}
.textCollapse {
  @apply font-Avenir;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}

.textFact {
  @apply text-strongblue mb-2 font-Fraunces;
  font-size: 36px !important;
  line-height: 50px;
}
</style>
