<template>
  <div id="app" @click="handleClicks">
    <transition name="fade" mode="out-in">
      <SiteIntro v-if="shouldShowIntro" />
    </transition>
    <nav-menu class="main-nav" name="main-menu" />
    <router-view v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
        @before-enter="transitionElement"
        @after-leave="completeTransition"
      >
        <component :key="$route.path" :is="Component" />
      </transition>
    </router-view>
    <app-footer />
    <transition name="fade">
      <site-loading v-if="loading" />
    </transition>
  </div>
  <!-- teleport target vue-universal-modal -->
  <div id="fibrogen-modals"></div>
</template>

<script>
import NavMenu from "./components/template-parts/NavMenu/NavMenu.vue";
import AppFooter from "./components/template-parts/Footer.vue";
import SiteLoading from "./components/utility/SiteLoading.vue";
import SiteIntro from "./components/utility/Intro.vue";
import AOS from "aos";


export default {
  components: {
    NavMenu,
    AppFooter,
    SiteLoading,
    SiteIntro,
  },
  data() {
    return {
      introShown: false,
      screenWidth: screen.width,
      site: this.$store.state.site,
      showMenu: true,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
    };
  },
  computed: {
    shouldShowIntro() {
      return !this.introShown && !sessionStorage.getItem("introShown");
    },
    loading() {
      return this.$store.state.site.loading;
    },
    logo() {
      if (this.site.logo) {
        return this.$store.getters.singleById({
          type: "media",
          id: this.site.logo,
        });
      }
    },
  },
  methods: {
    transitionElement() {
      this.beforeEnter;
      this.showMenu = true;
    },
    completeTransition() {
      this.showMenu = false;
    },
    deviceAsign() {
      if (this.screenWidth < 768) {
        this.isMobile = true;
        this.isTablet = false;
        this.isDesktop = false;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        this.isTablet = true;
        this.isMobile = false;
        this.isDesktop = false;
      } else if (this.screenWidth >= 1024) {
        this.isTablet = false;
        this.isMobile = false;
        this.isDesktop = true;
      }
    },
    beforeEnter() {
      this.$emit("scrollBeforeEnter");
    },
    decodeTitle(title) {
      const txt = document.createElement("textarea");
      txt.innerHTML = title;
      return txt.value;
    },
    convertToRelative(url) {
      const path = url.replace(this.$store.state.site.url, "");
      return path;
    },
    isExternal(url) {
      const domain = (url) => {
        return url.replace("http://", "").replace("https://", "").split("/")[0];
      };
      return domain(location.href) !== domain(url);
    },
    hasIntroShown() {
      if (sessionStorage.getItem("introShown")) {
        this.$store.state.site.introShown = true;
        this.introShown = true;
        return true;
      } else {
        return false;
      }
    },
    introComplete() {
      this.introShown = true;
      sessionStorage.setItem("introShown", true);
      this.$store.state.site.introShown = true;
    },
    getLinkEl(el) {
      while (el.parentNode) {
        if (el.tagName === "A") return el;
        el = el.parentNode;
      }
    },
    handleClicks(e) {
      const a = this.getLinkEl(e.target);
      if (a && a.href.includes(this.site.url)) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          e;
        // don't handle if has class 'no-router'
        if (a.className.includes("no-router")) return;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (a.target && a.target.includes("_blank")) return;
        // don't handle same page links
        let currentURL = new URL(a.href, window.location.href);
        if (currentURL && currentURL.pathname === window.location.pathname) {
          // if same page link has same hash prevent default reload
          if (currentURL.hash === window.location.hash) e.preventDefault();
          return;
        }
        // Prevent default and push to vue-router
        e.preventDefault();
        let path = a.href.replace(this.site.url, "");
        this.$router.push(path);
      }
    },
  },
  created() {
    this.$store.dispatch("getAcfOptions");
    AOS.init({ once: true });
  },
  mounted() {
    this.deviceAsign();
    this.hasIntroShown();
    window.addEventListener("resize", (e) => {
      this.deviceAsign();
      this.screenWidth = screen.width;
    });
  },
  destroyed() {
    window.removeEventListener("resize", (e) => {
      this.deviceAsign();
      this.screenWidth = screen.width;
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/reset";
@import "@/assets/styles/global";

.vue-universal-modal {
  z-index: 9999 !important;
}

/* Vue transition classes
-------------------------------------------- */

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity ease-out;
  transition-duration: 400ms;
  // transition-delay: 5ms;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity ease-in;
  transition-duration: 400ms;
  //transition-delay: 5ms;
}
</style>