<template>
  <div class="grid-content">
    <div :v-if="subtitle !== ''" class="subtitle" :class="getTextColor">
      {{ subtitle }}
    </div>
    <div class="title" :class="getTextColor" v-html="title" />
    <p class="description" :class="getTextColor">{{ description }}</p>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "ContentJoinForm",
  props: {
    description: String,
    subtitle: String,
    title: String,
    usingParallax: Boolean,
  },
  setup(props) {
    const { description, subtitle, title, usingParallax } = props;

    const getTextColor = computed(() => {
      return usingParallax ? "text-white" : "text-black";
    });

    return {
      subtitle: subtitle || "",
      title,
      description,
      getTextColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-content:deep() {
  @apply flex flex-col w-full mb-auto gap-7;
  .title {
    @apply text-[30px] leading-[45px] 
    md:leading-[70px] md:text-[60px] 
    lg:text-[64px] lg:leading-[78px]
    font-Fraunces;
    font-weight: 400;
    strong {
      @apply text-stronglight;
    }
  }
}

.subtitle {
  @apply uppercase text-[16px];
}

.description {
  @apply text-[18px] leading-[25px] md:text-[20px] md:leading-[30px];
  font-weight: 400;
}
</style>