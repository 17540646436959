<template>
  <div class="container-study-locations">
    <div v-if="loadingPaginateStudy">
      <p class="font-Avenir text-loading-study">{{ loadingPaginateStudy }}</p>
    </div>

    <div
      class="container-study-count"
      :class="getClassStudyCount"
      @click="toggleSlide"
      v-if="!isLoading"
    >
      <p v-if="data.length" class="text-study-count">
        {{ !activeSlide ? "Show&nbsp;" : "Hide&nbsp;" }}
      </p>
      <p class="text-study-count">study locations</p>
      <i v-if="data.length && !active" class="gg-chevron-right"></i>
      <i v-else-if="data.length && active" class="gg-chevron-down"></i>
    </div>
    <slide-up-down
      class="container-slide-down"
      v-model="activeSlide"
      :duration="700"
      v-if="data.length"
    >
      <input
        class="input-filter"
        id="inputFilterLocations"
        placeholder="Filter results"
        @input="onChangeFilter"
        v-model="textFilter"
      />

      <div
        v-if="data.length"
        class="container-study-item"
        id="slide-up-down-studies"
      >
        <div
          class="item-study"
          v-for="(m, index) in getDataForRender"
          :key="index"
        >
          <div class="container-info">
            <div class="w-full flex flex-col">
              <h6
                class="font-Avenir leading-[30px] text-[20px] font-semibold mb-5"
              >
                {{ (m.country || "").concat(m.state ? `, ${m.state}` : "") }}
              </h6>
              <p
                v-if="m.direction"
                class="font-Avenir leading-[30px] text-[16x]"
              >
                {{ m.direction }}
              </p>
              <p class="font-Avenir leading-[30px] text-[16x]">
                {{
                  (m.city || "")
                    .concat(m.state ? `, ${m.state}` : "")
                    .concat(m.country ? `, ${m.country}` : "")
                    .concat(m.zip ? `, ${m.zip}` : "")
                }}
              </p>
              <p
                v-if="m.investigator"
                class="font-Avenir leading-[30px] text-[16x]"
              >
                Principal Investigator:&nbsp;{{ m.investigator }}
              </p>
            </div>
            <div
              class="w-full flex flex-row gap-5 justify-items-end"
              title="Click to Join a Clinical Trial"
            >
              <p
                v-if="m.status === 'Recruiting'"
                class="text-strongblue text-status"
              >
                {{ m.status }}
              </p>
              <p v-else class="text-status">
                {{ m.status || "Not yet recruiting" }}
              </p>
              <div
                v-if="m.status === 'Recruiting'"
                class="button-join-study"
                @click="toggleModal"
              >
              Discover Eligibility
              </div>
              <div v-else class="button-join-study" @click="toggleModal">
                Contact us
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide-up-down>

    <UniversalModal v-model="activeModal" :close="toggleModal">
      <PopUpJoinVue
        :popUpLogo="popUpLogo"
        :popUpDescription="popUpDescription"
        :externalLink="externalLink"
        :poweredUrl="poweredUrl"
        :externalLogo="externalLogo"
        :closeModal="toggleModal"
      />
    </UniversalModal>
  </div>
</template>

<script>
import SlideUpDown from "vue3-slide-up-down";
import ModalJoinForm from "./ModalJoinForm.vue";
import PopUpJoinVue from "../../JoinForm/PopUpJoin.vue";
import { computed, ref } from "vue";
// local store
import { useStoreProvideInject } from "../composable/useStoreTrialMap";

export default {
  name: "StudyLocations",
  components: {
    SlideUpDown,
    ModalJoinForm,
    PopUpJoinVue,
  },
  props: ["form_settings"],
  setup(props) {
    const activeSlide = ref(false);
    const activeModal = ref(false);
    const textFilter = ref("");
    const dataFilter = ref([]);
    const { studyResult, isLoading, loadingPaginateStudy } =
      useStoreProvideInject();

    const _data = computed({ get: () => studyResult.value });

    const getClassStudyCount = computed(() => {
      return _data.value.length ? "hover:cursor-pointer" : "";
    });

    const toggleSlide = () => {
      activeSlide.value = !activeSlide.value;
    };

    const toggleModal = () => {
      activeModal.value = !activeModal.value;
    };

    const getDataForRender = computed(() => {
      if (textFilter.value.length > 3) return dataFilter.value;
      return dataFilter.value.length > 0 ? dataFilter.value : _data.value;
    });

    const parseText = (text) => {
      if (!text) return "";
      return text.toLowerCase().trim();
    };

    const includesText = (textParent, textSearch) => {
      if (!textParent || !textSearch) return false;
      return textParent.includes(textSearch);
    };

    const onChangeFilter = () => {
      const filter = textFilter?.value || "";
      if (filter.length > 3 && _data.value.length) {
        const resultFilter = [..._data.value].filter((val) => {
          const includesFullCountry = includesText(
            parseText(val.country),
            parseText(filter)
          );
          if (includesFullCountry) return true;
          const includesFullCity = includesText(
            parseText(val.city),
            parseText(filter)
          );
          if (includesFullCity) return true;
          const includesFullState = includesText(
            parseText(val.state),
            parseText(filter)
          );
          if (includesFullState) return true;
          const includesFullZip = includesText(
            parseText(val.zip),
            parseText(filter)
          );
          if (includesFullZip) return true;
          const includesFullDirection = includesText(
            parseText(val.direction),
            parseText(filter)
          );
          if (includesFullDirection) return true;
          const includesFullStatus =
            val.status && parseText(val.status) === parseText(filter);
          if (includesFullStatus) return true;
          const splitBySpace = filter.split(" ");
          splitBySpace.slice(0, 3).forEach((valSplit) => {
            if (includesText(parseText(val.country), parseText(valSplit))) {
              return true;
            }
            if (includesText(parseText(val.city), parseText(valSplit))) {
              return true;
            }
            if (includesText(parseText(val.state), parseText(valSplit))) {
              return true;
            }
            if (includesText(parseText(val.zip), parseText(valSplit))) {
              return true;
            }
            if (includesText(parseText(val.direction), parseText(valSplit))) {
              return true;
            }
          });
        });
        dataFilter.value = [...resultFilter];
      } else {
        dataFilter.value = [];
      }
      if (dataFilter.value.length) {
        const idScroll = document.getElementById("slide-up-down-studies");
        if (idScroll) {
          idScroll.scrollTop = 0;
        }
      }
    };

    const form_settings = props.form_settings;

    return {
      textFilter,
      dataFilter,
      onChangeFilter,
      isLoading,
      loadingPaginateStudy,
      form_settings,
      data: _data,
      activeSlide,
      activeModal,
      getClassStudyCount,
      toggleSlide,
      toggleModal,
      getDataForRender,
      poweredUrl: form_settings.powered_by_url,
      externalLogo: form_settings.external_url_logo,
      popUpLogo: form_settings.pop_up_logo,
      externalLink: form_settings.contact_privacy_link,
      popUpDescription: form_settings.pop_up_description,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-slide-down {
  @apply p-0 mt-[-1.5rem];
}
.input-filter {
  @apply w-full min-w-[150px] max-w-[350px] mt-3 mb-7
  block p-2 font-Avenir w-full text-sm text-gray-900 bg-gray-50 rounded-[10px]
   border border-gray-300 focus:ring-blue-500 focus:border-blue-500
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
.input-filter::placeholder {
  @apply font-Avenir;
}
.container-modal-form {
  @apply font-Avenir relative w-full flex flex-col items-center pr-12 lg:pr-0;
}
.container-study-locations {
  @apply w-full py-5 flex flex-col gap-5 min-h-[250px] mx-auto;
}
.container-study-count {
  @apply flex flex-row w-full pb-5;
}
.text-loading-study {
  @apply text-black text-[16px] font-Avenir;
}
.text-study-count {
  @apply text-black text-[20px] font-semibold font-Avenir;
}

.text-status {
  @apply my-auto ml-auto font-Avenir leading-[30px] text-[20px] font-semibold;
}
.container-study-item {
  @apply min-h-[250px] gap-7 max-h-[1000px] overflow-auto pr-2 flex flex-col;
}

.item-study {
  @apply w-full flex flex-col hover:bg-gray-100 p-3;
}

.container-info {
  @apply w-full flex flex-col md:flex-row gap-5;
}

.button-join-study {
  @apply bg-black rounded-[10px] w-auto my-auto border border-black
    text-[16px] text-white py-3 px-8 justify-self-end
    transition-all duration-500 ease-in-out
    hover:bg-white hover:text-black hover:cursor-pointer;
}

.gg-chevron-down {
  @apply text-strongblue transition-all duration-500 ease-in-out ml-2;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-right {
  @apply text-strongblue transition-all duration-500 ease-in-out ml-2;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}
</style>
