<template>
  <section class="pagination">
    <a
      class="pagination__previous"
      :class="{disabled: current === 1}"
      href="#"
      rel="previous"
      v-html="'&lsaquo; Previous'"
      @click.prevent="gotoPage(current - 1)"
    ></a>
    <div class="pages">
      <a 
        v-for="item in total"
        class="pagination__item"
        :class="{disabled : current === item, active: current === item}"
        href="#"
        :rel="`page-${item}`"
        @click.prevent="gotoPage(item)"
        >
        {{item}}
      </a>
    </div>
    <a
      class="pagination__next"
      :class="{disabled: current === total}"
      href="#"
      rel="next"
      v-html="'Next &rsaquo;'"
      @click.prevent="gotoPage(current + 1)"
    ></a>
  </section>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    gotoPage(page) {
      if (! page || page > this.total) return
      let path = this.$route.path
      if (this.current === 1 && page !== 1) {
        path += `page/${page}/`
      } else if (page === 1) {
        path = path.replace(`page/${this.current}/`, '')
      } else {
        path = path.replace(`page/${this.current}/`, `page/${page}/`)
      }
      this.$router.push(path)
    }
  }
}

</script>

<style lang="scss">

  .pagination {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      font-size: 1.8rem;
      text-decoration: none;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.active {
        font-weight: bold;
        opacity: 1;
      }
    }

    .pagination__item {
      padding: 5px;
    }
  }
</style>

