<template>
  <div class="image-module-component">
    <img :src="data.module_image_url.url" :alt="data.module_image_url.alt" />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: [
    'data'
  ],
  mounted() {
    // console.log(this.data);
  }
}
</script>

<style lang='scss' scoped>
.image-module-component {
  @apply w-full max-w-screen-xl mx-auto my-10 lg:my-20 px-6 lg:px-0;
  img {
    @apply min-h-[300px] max-h-[300px] lg:min-h-[600px] lg:max-h-[600px] object-cover w-full;
  }
}
</style>