<template>
    <div class="container-map" @mouseleave="mouseleaveMarker">
      <div v-if="isLoading" class="spinner-container">
        <div
          style="border-top-color: transparent"
          class="
            w-12
            h-12
            border-4 border-blue-400 border-solid
            rounded-full
            animate-spin
            mx-auto
            my-auto
          "
        ></div>
      </div>
      <div v-if="isLoading" class="is-loading-search"></div>
      <div class="container-input-places" @mouseleave="mouseleaveMarker">
        <GMapAutocomplete
          class="input-places"
          id="g-map-auto-complete"
          :placeholder="'city, state, country or zip'"
          @place_changed="setPlace"
        />
      </div>
      <GMapMap
        :options="{
          ...options,
          zoom,
          center,
        }"
        map-type-id="terrain"
        style="width: 100%; height: 600px"
      >
        <GMapMarker
          v-for="(m, index) in listMarkers"
          :key="index"
          :position="m.location"
          :clickable="true"
          :draggable="false"
          :icon="{
            url:
              idActiveHover === index || idActiveHover === -1
                ? iconMarkerActive
                : iconMarkerGray,
            iconMarkerRed: { width: 12, height: 12 },
          }"
          @mouseover="mouseOverMarker(index)"
          @mouseleave="mouseleaveMarker"
          @click="clickMarker(index)"
        >
          <GMapInfoWindow
            :key="index"
            :opened="index === idActiveHover"
            @mouseleave="mouseleaveMarker"
            class="max-w-[380px]"
            :id="index"
          >
            <div
              class="
                w-full
                text-white
                px-3
                py-5
                font-Avenir
                flex flex-col
                gap-3
                z-9
              "
              @mouseleave="mouseleaveMarker"
            >
              <p
                v-if="m.info.direction"
                class="font-Avenir text-[20x] font-semibold"
              >
                {{ m.info.direction }}
              </p>
              <p class="font-Avenir text-[16x]">
                {{
                  (m.info.city || "")
                    .concat(m.info.country ? `, ${m.info.country}` : "")
                    .concat(m.info.zip ? `, ${m.info.zip}` : "")
                }}
              </p>
              <p v-if="m.info.investigator" class="font-Avenir text-[16x]">
                Principal Investigator:&nbsp;{{ m.info.investigator }}
              </p>
              <p v-if="m.info.status" class="font-Avenir text-[16x]">
                Status:&nbsp;<strong
                  class="font-Avenir text-[25x] uppercase"
                  :class="classTextStatus(m.info.status)"
                  >{{ m.info.status }}</strong
                >
              </p>
              <div
                v-if="m.info.status === `Recruiting`"
                class="button-marker-modal"
                @click="toggleModal"
              >
              Discover Eligibility
              </div>
              <div v-else class="button-marker-modal" @click="toggleModal">
                Contact us
              </div>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
  
        <GMapMarker
          v-if="markerSearch && locationSearch?.country"
          :position="markerSearch"
          @mouseover="mouseOverMarker(`CUSTOM_SEARCH`)"
          @mouseleave="mouseleaveMarker"
          :icon="{
            url: icon,
            iconMarkerRed: { width: 10, height: 10 },
          }"
          :clickable="true"
          :draggable="false"
        >
          <GMapInfoWindow
            :opened="idActiveHover === 'CUSTOM_SEARCH' && locationSearch?.country"
            class="max-w-[350px] z-9"
            @mouseleave="mouseleaveMarker"
          >
            <div
              class="w-full text-white p-7 font-Avenir flex flex-col gap-5 z-9"
              @mouseleave="mouseleaveMarker"
            >
              <p class="font-Avenir text-[16x] break-words text-center">
                {{
                  (locationSearch.city || "")
                    .concat(
                      locationSearch.state ? ` ${locationSearch.state}` : ""
                    )
                    .concat(
                      locationSearch.country ? ` ${locationSearch.country}` : ""
                    )
                    .concat(locationSearch.zip ? ` ${locationSearch.zip}` : "")
                }}
              </p>
              <div class="button-marker-modal" @click="toggleModal">
                Contact us
              </div>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>
     <UniversalModal v-model="activeModal" :close="toggleModal">
          <PopUpJoinVue 
          :popUpLogo="popUpLogo"
            :popUpDescription="popUpDescription"
            :externalLink="externalLink"
            :poweredUrl="poweredUrl"
            :externalLogo="externalLogo"
          :closeModal="toggleModal" />
      </UniversalModal>
  </template>
  
  <script>
  // google map library
  import stylesMap from "./stylesMap";
  import "./overrideGoogleMap.css";
  import IconMarkerActive from "@/assets/img/markerTrialMap.svg";
  import IconMarkerGray from "@/assets/img/gray-marker.svg";
  import IconMarkerRed from "@/assets/img/red-marker.svg";
  
  // utils
  import { getFormatAddress } from "../utils";
  
  // vue
  import { onMounted, onUnmounted, computed, ref } from "vue";
  
  // components
  import ModalJoinForm from "../StudyLocations/ModalJoinForm.vue";
  import PopUpJoinVue from "../../JoinForm/PopUpJoin.vue";
  
  // local store
  import { useStoreProvideInject } from "../composable/useStoreTrialMap";
  export default {
    name: "MapContainer",
    components: {
      ModalJoinForm,
      PopUpJoinVue
    },
    props: ["form_settings"],
    setup(props) {
      const {
        centerMap,
        zoomMap,
        googleMapKey,
        fetchAPITrials,
        setlocationSearch,
        isLoading,
        listMarkers,
        markerSearch,
        locationSearch,
        centerMapWithMarkerClick,
      } = useStoreProvideInject();
  
      const activeModal = ref(false);
  
      const idActiveHover = ref(-1);
  
      const _center = computed({ get: () => centerMap.value });
      const _zoom = computed({ get: () => zoomMap.value });
      const _listMarkers = computed({ get: () => listMarkers.value });
      const _markerSearch = computed({ get: () => markerSearch.value });
      const _locationSearch = computed({ get: () => locationSearch.value });
  
      const classTextStatus = (status) => {
        return status === "Recruiting" ? "text-strongblue" : "text-red-500";
      };
  
      const clickMarker = (id) => {
        centerMapWithMarkerClick(id);
      };
  
      const setPlace = (place) => {
        idActiveHover.value = -1;
        let newLocationSearch = {
          zip: null,
          country: null,
          state: null,
          city: null,
          searchText: null,
          lat: null,
          lng: null,
        };
        if (!place) setlocationSearch(newLocationSearch);
        if (place.geometry) {
          newLocationSearch = {
            ...newLocationSearch,
            ...getFormatAddress(place.address_components),
          };
          newLocationSearch.lat = place.geometry.location.lat();
          newLocationSearch.lng = place.geometry.location.lng();
        }
        newLocationSearch.searchText = place.name;
        setlocationSearch(newLocationSearch);
      };
  
      const inputGooglePlaceListener = () => {
        const inputPlace = document.getElementById("g-map-auto-complete");
        let isEmpty = false;
        if (!inputPlace?.value) {
          isEmpty = true;
        } else if (inputPlace?.value) {
          isEmpty = inputPlace.value.trim().length === 0;
        }
        if (isEmpty) {
          setPlace();
        }
      };
  
      onMounted(() => {
        fetchAPITrials();
        const inputPlace = document.getElementById("g-map-auto-complete");
        if (inputPlace) {
          inputPlace.addEventListener("input", inputGooglePlaceListener);
        }
      });
  
      onUnmounted(() => {
        const inputPlace = document.getElementById("g-map-auto-complete");
        if (inputPlace) {
          inputPlace.removeEventListener("input", inputGooglePlaceListener);
        }
      });
  
      const toggleModal = () => {
        activeModal.value = !activeModal.value;
      };
  
      const mouseOverMarker = (index) => {
        idActiveHover.value = index;
      };
  
      const mouseleaveMarker = () => {
        idActiveHover.value = -1;
      };
  
      const form_settings = props.form_settings;
  
      return {
        clickMarker,
        form_settings,
        poweredUrl: form_settings.powered_by_url,
        externalLogo: form_settings.external_url_logo,
        popUpLogo: form_settings.pop_up_logo,
        externalLink: form_settings.contact_privacy_link,
        popUpDescription: form_settings.pop_up_description,
        activeModal,
        toggleModal,
        apiKey: googleMapKey,
        setPlace: setPlace,
        zoom: _zoom,
        center: _center,
        options: {
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: true,
          panControl: false,
          styles: stylesMap,
        },
        isLoading,
        idActiveHover,
        listMarkers: _listMarkers,
        markerSearch: _markerSearch,
        locationSearch: _locationSearch,
        iconMarkerActive: IconMarkerActive,
        iconMarkerGray: IconMarkerGray,
        iconMarkerRed: IconMarkerRed,
        mouseOverMarker,
        mouseleaveMarker,
        classTextStatus,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container-modal-form {
    @apply font-Avenir relative;
  }
  .container-map {
    @apply relative w-full h-[600px] z-0;
  }
  
  .container-input-places {
    @apply absolute z-1 mt-[1rem] ml-[1rem] w-[250px] md:w-[350px] p-3 rounded-[10px] bg-white;
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.15);
  }
  .input-places {
    @apply w-full
    block p-2 font-Avenir w-full text-sm text-gray-900 bg-gray-50 rounded-[10px]
     border border-gray-300 focus:ring-blue-500 focus:border-blue-500
      dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
       dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }
  
  .input-places::placeholder {
    @apply font-Avenir;
  }
  
  .is-loading-search {
    @apply bg-gray-400 flex flex-col w-full h-full absolute z-2 transition-all duration-700 ease-in-out opacity-20;
  }
  
  .spinner-container {
    @apply w-full flex flex-col absolute z-2 transition-all duration-700 ease-in-out w-full h-full;
  }
  
  .button-marker-modal {
    @apply bg-white rounded-[10px] w-auto mx-auto border border-white
      text-[16px] text-black py-2 px-8
      mx-auto
      transition-all duration-500 ease-in-out
      hover:bg-gray-300 hover:text-black hover:font-bold hover:cursor-pointer;
  }
  </style>