<template>
  <div class="containerInfoList">
    <div class="listCollapse" v-html="data" />
  </div>
</template>
<script>
export default {
  name: "ContentCollapse_4",
  props: ["data"],
  created() {},
};
</script>
<style lang="scss" scoped>
.containerInfoList:deep() {
  .listCollapse {
    p {
       line-height: 39.16px !important;
      @apply font-Avenir text-[20px] md:text-[26px] pr-3 pb-3;
    }
    li {
      @apply font-Avenir text-[20px];
      font-weight: 400 !important;
      line-height: 31px !important;
    }
    h4 {
      @apply font-Avenir text-[26px];
      line-height: 40px !important;
    }

    strong {
      font-weight: 500 !important;
    }
  }
}
</style>
