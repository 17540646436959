<template>
  <div class="container-hero">
    <ContentHero v-bind="data.content" />
    <ImagesHero :imagelist="data.images.imagelist" />
  </div>
</template>
<script>
import ContentHero from "./ContentHero.vue";
import ImagesHero from "./ImagesHero.vue";

export default {
  components: {
    ContentHero,
    ImagesHero,
  },
  props: ["data"],
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.container-hero {
  @apply w-full pb-7 lg:pb-48 px-7 grid grid-cols-1 gap-7 max-w-screen-xl lg:mx-auto lg:grid-cols-2 lg:px-0;
}
</style>
