<template>
  <div class="containerPopUp">
    <img class="imgPopUp" :src="popUpLogo.url" :alt="popUpLogo.alt" />
    <p class="descriptionPopUp" v-html="popUpDescription"></p>

    <div class="containerButtons">
      <button
        @click="closeModal"
        class="
        cursor-pointer
          font-Avenir
          bg-white
          border-[2px] border-solid border-black
          rounded-[10px]
          w-auto
          text-[16px] text-black
          py-4
          text-center
          font-semibold
          px-12
          transition-all
          duration-500
          ease-in-out
          hover:bg-black hover:text-white hover:border-[2px] hover:border-black
        "
      >
        Cancel
      </button>
      <a
        @click="closeOk"
        :href="getURL()"
        target="_blank"
        class="
        cursor-pointer
          font-Avenir
          bg-black
          border-[2px] border-solid border-black
          rounded-[10px]
          w-auto
          text-[16px] text-white
          py-4
          px-12
          text-center
          font-semibold
          transition-all
          duration-500
          ease-in-out
          hover:bg-white hover:text-black hover:border-[2px] hover:border-black
        "
      >
        Okay
      </a>
    </div>
    <div class="grid-powered">
      <p class="font-Avenir">Powered by</p>
      <a class="font-Avenir" alt="Powered by" :src="poweredUrl" target="_blank">
        <img
          class="imgExternal"
          :src="externalLogo.url"
          :alt="externalLogo.alt"
        />
      </a>
    </div>
  </div>
</template>

<script>
import useUUID from "../../../useComposable";
import { event } from "vue-gtag";
const { userID } = useUUID();
let utm_gaClientId = "";
let utm_gaSessionId = "";

export default {
  name: "PopUpJoin",
  components: {},
  props: [
    "popUpDescription",
    "externalLink",
    "poweredUrl",
    "externalLogo",
    "popUpLogo",
    "closeModal",
    "UUID",
  ],
  setup(props) {
    const { userID } = useUUID();
    const closeOk = () => {
      if (props.closeModal) {
        props.closeModal();
      }
    }
    const getURL = () => {
      dataLayer.forEach((item, index) => {
        if ("Ya" in item) {
          if ("gtagApiResult" in item.Ya) {
            utm_gaClientId = item.Ya.gtagApiResult.client_id;
            utm_gaSessionId = item.Ya.gtagApiResult.session_id;
          }
        }
      });

      return props.poweredUrl + '?utm_userid=' + userID.value + '&utm_gaClientId=' + utm_gaClientId + '&utm_gaSessionId=' + utm_gaSessionId
    }
    const closeModal = () => {
      if (props.closeModal) {
        props.closeModal();
      }
    };
    return {
      closeModal,
      closeOk,
      getURL,
      popUpDescription: props.popUpDescription,
      externalLink: props.externalLink,
      poweredUrl: props.poweredUrl,
      externalLogo: props.externalLogo,
      popUpLogo: props.popUpLogo,
      user_ID: userID.value,
      gaClientId: utm_gaClientId,
      gaSessionId: utm_gaSessionId,
    };
  },
};
</script>

<style lang="scss" scoped>
.containerPopUp {
  @apply w-[90%] max-w-[800px] md:w-[80%] lg:w-[70%] bg-white p-7 lg:p-12 rounded-[10px] flex flex-col min-h-[350px] gap-7;
}

.grid-powered {
  @apply flex flex-row gap-3 mx-auto transition-all ease-in-out duration-500;
  p {
    @apply text-[16px] mb-0;
  }
  a {
    @apply text-[16px] mb-0 text-primary hover:cursor-pointer;
  }
}

.imgExternal {
  @apply max-w-[100px];
}

.imgPopUp {
  @apply mx-auto w-[70%] max-w-[400px];
}
.descriptionPopUp,
.descriptionPopUp:deep() a {
  @apply text-[16px] leading-[26px] md:text-[24px] md:leading-[35px] text-center font-Avenir mx-auto;
}

.descriptionPopUp:deep() a {
  @apply underline;
}

.containerButtons {
  @apply justify-center gap-5 w-full flex flex-col-reverse md:flex-row md:gap-12 justify-items-center;
}
</style>